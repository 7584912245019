// Dom św. Marty

import React from 'react';
import { Platform, View, ScrollView, Text, Image, Animated as RNAnimated, TouchableWithoutFeedback, PixelRatio, useWindowDimensions } from 'react-native';
import { SharedElement } from 'react-navigation-shared-element';
import { goscie, dawno, aktualnie } from './zdjeciaD3';
import { Img } from '../components/Img';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import FullWidthImage from 'react-native-fullwidth-image';
import { styles, PREFIX } from './stylesDomki';
import { O } from "../components/Obrazy";
import * as Obrazy from "../components/Obrazy";

export const D3 = (props) => {
    const navigation = props.navigation;
    const domek = props.route.params.domek;
    const opacity = React.useMemo(() => new RNAnimated.Value(0), []);
    const windim = useWindowDimensions();

    function fontSize(size) {
        return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
    }

    return (
        <Kratka style={styles.container}>
        <ScrollView>
            { Platform.OS === 'web' &&
            <FullWidthImage source={O.D3.D3_Domek} />
            }
            { Platform.OS !== 'web' &&
            <Image source={O.D3.D3_Domek} style={styles.domek} />
            }
            <View style={styles.contentContainer}>
                <View style={styles.content1}>
                    <Image source={Obrazy.Pionek} resizeMode="contain" style={styles.pionek} />
                </View>
                <View style={[styles.content2, styles.contentOpis]}>
                    <Text style={[styles.opis, { fontSize: fontSize(24) }]}>{domek.opis}</Text>
                </View>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.content1}>
                    <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Pionek i inne ważne figury…</Text>
                </View>
                <View style={[styles.content2, styles.galeria]}>
                    {goscie.map((zdjecie, i) => 
                    <TouchableWithoutFeedback key={"zd"+i} onPress={() => navigation.push("Zdjecie", { zdjecie, indeks: i, zdjecia: goscie })}>
                        <SharedElement id={zdjecie.id}>
                            <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                        </SharedElement>
                    </TouchableWithoutFeedback>
                    )}
                </View>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.content1}>
                    <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Dawno, dawno temu…</Text>
                </View>
                <View style={[styles.content2, styles.galeria]}>
                    {dawno.map((zdjecie, i) => 
                    <TouchableWithoutFeedback key={"zd"+i} onPress={() => navigation.push("Zdjecie", { zdjecie, indeks: i, zdjecia: dawno })}>
                        <SharedElement id={zdjecie.id}>
                            <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                        </SharedElement>
                    </TouchableWithoutFeedback>
                    )}
                </View>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.content1}>
                    <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Aktualnie…</Text>
                </View>
                <View style={[styles.content2, styles.galeria]}>
                    {aktualnie.map((zdjecie, i) => 
                    <TouchableWithoutFeedback key={"za"+i} onPress={() => navigation.push("Zdjecie", { zdjecie, indeks: i, zdjecia: aktualnie })}>
                        <SharedElement id={zdjecie.id}>
                            <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                        </SharedElement>
                    </TouchableWithoutFeedback>
                    )}
                </View>
            </View>
            <Stopka />
        </ScrollView>
        </Kratka>
    );
}
