import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D10/";
const PREVIEW = O.D10;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D10_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1920, height: 1374},
      opis: 'Do 1982 r. stały tu: po lewej - Dom św. Wawrzyńca, po prawej - Biały Dom',
    },
    {
      id: 2,
      preview: PREVIEW.D10_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 800, height: 515},
      opis: 'Biały Dom, 1960-1985',
    },
    {
      id: 3,
      preview: PREVIEW.D10_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 1154, height: 739},
      opis: 'Rok 1985',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D10_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: '',
  },
  {
    id: 102,
    preview: PREVIEW.D10_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1440},
    opis: '',
  },
  {
    id: 103,
    preview: PREVIEW.D10_a3p,
    zdjecie: {uri: PATH + "a3.jpg", width: 1440, height: 1920},
    opis: 'Pomnik św. Urszuli',
  },
];
