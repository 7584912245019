import React from 'react';
import { StyleSheet, Platform, View, Text, useWindowDimensions } from 'react-native';

const styles = StyleSheet.create({
    stopka: {
        zIndex: 1,
        height: 64,
        borderTopColor: '#526060',
        borderTopWidth: 1,
        borderBottomColor: '#526060',
        borderBottomWidth: 10,
        width: "100%",
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    stopkaText: {
        paddingLeft: 45,
        paddingTop: 10,
        paddingBottom: 10,
        fontFamily: 'CenturyGothic-Regular',
        fontSize: ( Platform.OS === 'web' ) ? 16 : 10,
        color: "#526060",
    },
});

export const Stopka = () => {
    const windim = useWindowDimensions();

    function fontSize(size) {
        return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
    }

    return (
        <View style={styles.stopka}>
            <Text style={[styles.stopkaText, { fontSize: fontSize(16) }]}>św. Urszuli Ledóchowskiej 1, 62-045 Pniewy, Polska | +48 61 293 81 00</Text>
        </View>
    );
}