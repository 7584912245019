import React, { /*useEffect, useLayoutEffect, useRef,*/ useState } from 'react'
import { Platform, PixelRatio, StyleSheet, View, Text, Image, ScrollView, TouchableOpacity, Linking, useWindowDimensions } from 'react-native';
//import { SafeAreaView } from 'react-native-safe-area-context';
//import { useWindowDimensions } from '../components/Utility';
//import Animated, { useCode, Value, set, concat, multiply, divide, sub } from "react-native-reanimated";
//import { useWindowDimensions } from '../components/Utility';
import { createStackNavigator } from '@react-navigation/stack';
import { headerStyles, LogoTitle } from '../components/LogoTitle';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import FullWidthImage from 'react-native-fullwidth-image';
import * as Obrazy from "../components/Obrazy";
import { PREFIX } from "../Sanktuarium/stylesDomki";
import Piosenki from "./Piosenki";
import Quizy from "./Quizy";
import Quiz from "./Quiz";
import Quiz2 from "./Quiz2";
import { shadow } from '../components/Narzedzia';

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  baner: {
    width: '100%',
    height: undefined,
    aspectRatio: 1920 / 356,
  },
  pionek: {
    width: 200 / PixelRatio.get(),
    height: 200 / PixelRatio.get() * 560 / 258,
    aspectRatio: 560 / 258,
    position: "absolute",
    bottom: -100 / PixelRatio.get(), 
    alignSelf: "center",
  },
  contentContainer: {
    flexDirection: "row",
  },
  content1: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content2: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentOpis: {
    padding: "8%",
    backgroundColor: 'white',
  },
  tekst: {
    textAlign: "justify",
    fontFamily: "CenturyGothic-Regular",
    fontSize: (Platform.OS === "web" ) ? 24 : 12,
  },
  pikto: {
    padding: 20,
    top: 0,
    left: 0,
    alignItems: "center",
    ...Platform.select({ "web": {
      transition: "all 0.5s ease" //, transform 1.2s ease-out",
    }})
  },
});

const GryScreen = ({navigation, route}) => {
  const windim = useWindowDimensions();

  function fontSize(size) {
    return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
  }

  async function openURL(url, target) {
      if ( ! url || url === "" )
        return;
      if ( Platform.OS == 'web' )
        window.open(url, target);
      else
      {
        const supported = await Linking.canOpenURL(url);
        if ( supported )
          await Linking.openURL(url);
      }
    }

    const PIKTO_SIZE = Math.floor(windim.width / 5);
    //const PIKTO_SIZE_POL = PIKTO_SIZE / 2;
    const SHADOW = {left: -10, top: -10, ...shadow({
      elevation: 20,
      color: 'black',
      opacity: 0.5,
      radius: 20,
      offsetWidth: 5,
      offsetHeight: 5,
    })};

    const [ s1, setS1 ] = useState(null);
    const [ s2, setS2 ] = useState(null);
    const [ s3, setS3 ] = useState(null);
    const [ s4, setS4 ] = useState(null);
    const [ s5, setS5 ] = useState(null);
    const [ s6, setS6 ] = useState(null);
    const [ s7, setS7 ] = useState(null);
    const [ s8, setS8 ] = useState(null);
    const [ s9, setS9 ] = useState(null);
    const [ s10, setS10 ] = useState(null);
    const [ s11, setS11 ] = useState(null);
    const [ s12, setS12 ] = useState(null);
    /*
    const p1 = useRef();
    const p2 = useRef();
    const p3 = useRef();
    const p4 = useRef();
    const p5 = useRef();
    const p6 = useRef();

    const mouseX = new Value(0);
    const mouseY = new Value(0);
    const p1x = new Value(0);
    const p1y = new Value(0);
    const p2x = new Value(0);
    const p2y = new Value(0);
    const p3x = new Value(0);
    const p3y = new Value(0);
    const p4x = new Value(0);
    const p4y = new Value(0);
    const p5x = new Value(0);
    const p5y = new Value(0);
    const p6x = new Value(0);
    const p6y = new Value(0);
    const constrain = 30;

    const r1x = multiply(divide(sub(sub(mouseY, p1y), PIKTO_SIZE_POL), constrain), -1);
    const r1y = divide(sub(sub(mouseX, p1x), PIKTO_SIZE_POL), constrain);
    const r2x = multiply(divide(sub(sub(mouseY, p2y), PIKTO_SIZE_POL), constrain), -1);
    const r2y = divide(sub(sub(mouseX, p2x), PIKTO_SIZE_POL), constrain);
    const r3x = multiply(divide(sub(sub(mouseY, p3y), PIKTO_SIZE_POL), constrain), -1);
    const r3y = divide(sub(sub(mouseX, p3x), PIKTO_SIZE_POL), constrain);
    const r4x = multiply(divide(sub(sub(mouseY, p4y), PIKTO_SIZE_POL), constrain), -1);
    const r4y = divide(sub(sub(mouseX, p4x), PIKTO_SIZE_POL), constrain);
    const r5x = multiply(divide(sub(sub(mouseY, p5y), PIKTO_SIZE_POL), constrain), -1);
    const r5y = divide(sub(sub(mouseX, p5x), PIKTO_SIZE_POL), constrain);
    const r6x = multiply(divide(sub(sub(mouseY, p6y), PIKTO_SIZE_POL), constrain), -1);
    const r6y = divide(sub(sub(mouseX, p6x), PIKTO_SIZE_POL), constrain);

    const getPosition = ()=>{
      let view = p1.current.measureInWindow ? p1.current : p1.current.getNode();
      view.measureInWindow((x, y)=>{ p1x.setValue(x); p1y.setValue(y); });
      view = p2.current.measureInWindow ? p2.current : p2.current.getNode();
      view.measureInWindow((x, y)=>{ p2x.setValue(x); p2y.setValue(y); });
      view = p3.current.measureInWindow ? p3.current : p3.current.getNode();
      view.measureInWindow((x, y)=>{ p3x.setValue(x); p3y.setValue(y); });
      view = p4.current.measureInWindow ? p4.current : p4.current.getNode();
      view.measureInWindow((x, y)=>{ p4x.setValue(x); p4y.setValue(y); });
      view = p5.current.measureInWindow ? p5.current : p5.current.getNode();
      view.measureInWindow((x, y)=>{ p5x.setValue(x); p5y.setValue(y); });
      view = p6.current.measureInWindow ? p6.current : p6.current.getNode();
      view.measureInWindow((x, y)=>{ p6x.setValue(x); p6y.setValue(y); });
    };

    useLayoutEffect(getPosition);
    useEffect(()=>{
      window.addEventListener("scroll", getPosition);
      return () => { window.removeEventListener("scroll", getPosition); }
    });
*/

    return (
      <Kratka style={styles.container}
      /*
      onMouseMove={(e)=>{
        mouseX.setValue(e.clientX);
        mouseY.setValue(e.clientY);
      }}
      */
     >
      <ScrollView>
        { Platform.OS === 'web' &&
            <FullWidthImage source={Obrazy.GryBaner} />
        }
        { Platform.OS !== 'web' &&
            <Image source={Obrazy.GryBaner} style={styles.baner} />
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Image source={Obrazy.Pionek} resizeMode="contain" style={styles.pionek} />
          </View>
          <View style={[styles.content2, styles.contentOpis]}>
            <Text style={[styles.tekst, { fontSize: fontSize(24) }]}>Witam w strefie gier urszulankowych :) Cieszymy się, że chcesz się do nas przyłączyć. Czuj się swobodnie. Tu nie panują żadne reguły, no chyba że jedna. Baw się dobrze :) Każdą z gier możesz pobrać i bawić się do woli.</Text>
          </View>
        </View>
        <View style={[styles.contentContainer, { backgroundColor: "#c9c9c9", paddingVertical: "4%" }]}>
          <View style={styles.content1}>
            <Text style={[styles.tekst, { fontSize: fontSize(24) }]}>Pionki św. Urszuli</Text>
          </View>
          <View style={styles.content2}>
            <Image source={Obrazy.Pionki} resizeMode="contain" style={{ width: Math.floor(windim.width / 4) * 1087 / 500, height: Math.floor(windim.width / 4) }} />
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly", margin: "4%", marginTop: "8%" }}>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/krzyzowka.pdf", "_blank")}>
{/*
            <View style={{ perspective: 350 }}>
*/}
            <View /*ref={p1}*/ style={[styles.pikto, s1/*, { transform: [{ rotateX: concat(r1x, 'deg') }, { rotateY: concat(r1y, 'deg') }] }*/]} onMouseOver={()=>{
                setS1(SHADOW);
              }} onMouseOut={()=>{
                setS1(null);
              }}>
              <Image source={Obrazy.GryKrzyzowka} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Krzyżówka</Text>
            </View>
{/*
            </View>
*/}
            </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/memory.pdf", "_blank")}>
            <View style={[styles.pikto, s2]} onMouseOver={()=>{
                setS2(SHADOW);
              }} onMouseOut={()=>{
                setS2(null);
              }}>
              <Image source={Obrazy.GryMemory} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Memory</Text>
              </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/kolorowanka.pdf", "_blank")}>
            <View style={[styles.pikto, s3]} onMouseOver={()=>{
                setS3(SHADOW);
              }} onMouseOut={()=>{
                setS3(null);
              }}>
              <Image source={Obrazy.GryKolorowanka} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Kolorowanka</Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly", margin: "4%" }}>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/rebusy.pdf", "_blank")}>
            <View style={[styles.pikto, s4]} onMouseOver={()=>{
                setS4(SHADOW);
              }} onMouseOut={()=>{
                setS4(null);
              }}>
              <Image source={Obrazy.GryRebusy} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Rebusy</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/ubieranka.pdf", "_blank")}>
            <View style={[styles.pikto, s5]} onMouseOver={()=>{
                setS5(SHADOW);
              }} onMouseOut={()=>{
                setS5(null);
              }}>
              <Image source={Obrazy.GryWycinanka} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Wycinanka</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/trimino.pdf", "_blank")}>
            <View style={[styles.pikto, s6]} onMouseOver={()=>{
                setS6(SHADOW);
              }} onMouseOut={()=>{
                setS6(null);
              }}>
              <Image source={Obrazy.GryTrimino} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Trimino</Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly", margin: "4%" }}>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/test.pdf", "_blank")}>
            <View style={[styles.pikto, s7]} onMouseOver={()=>{
                setS7(SHADOW);
              }} onMouseOut={()=>{
                setS7(null);
              }}>
              <Image source={Obrazy.GryTestCzujnosci} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Test czujności</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/graf.pdf", "_blank")}>
            <View style={[styles.pikto, s8]} onMouseOver={()=>{
                setS8(SHADOW);
              }} onMouseOut={()=>{
                setS8(null);
              }}>
              <Image source={Obrazy.GryGraf} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Grafonotka</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/quest.pdf", "_blank")}>
            <View style={[styles.pikto, s9]} onMouseOver={()=>{
                setS9(SHADOW);
              }} onMouseOut={()=>{
                setS9(null);
              }}>
              <Image source={Obrazy.GryQUesty} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Questy</Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly", margin: "4%" }}>
          <TouchableOpacity onPress={() => openURL(PREFIX + "piecpiaty.pl/pobieralnia/mysli.pdf", "_blank")}>
            <View style={[styles.pikto, s10]} onMouseOver={()=>{
                setS10(SHADOW);
              }} onMouseOut={()=>{
                setS10(null);
              }}>
              <Image source={Obrazy.GryMysli} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Myśli św. Urszuli</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate("Quizy")}>
            <View style={[styles.pikto, s11]} onMouseOver={()=>{
                setS11(SHADOW);
              }} onMouseOut={()=>{
                setS11(null);
              }}>
              <Image source={Obrazy.GryInne} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Quizy</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate("Piosenki")}>
            <View style={[styles.pikto, s12]} onMouseOver={()=>{
                setS12(SHADOW);
              }} onMouseOut={()=>{
                setS12(null);
              }}>
              <Image source={Obrazy.GryPiosenki} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Piosenki</Text>
            </View>
          </TouchableOpacity>
        </View>
        <Stopka />
      </ScrollView>
      </Kratka>
    );
}

const Stack = createStackNavigator();

const Gry = ({navigation, route}) => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerBackTitleVisible: false,
          headerStyle: headerStyles.header,
          headerTintColor: 'white',
          headerTitleStyle: headerStyles.headerTitle,
          headerTitleContainerStyle: headerStyles.headerContainer,
          headerTitle: props => <LogoTitle {...props} navigation={navigation}>Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach</LogoTitle>,
          title: "Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach",
        }}>
        <Stack.Screen name="GryScreen" component={GryScreen} />
        <Stack.Screen name="Quizy" component={Quizy} />
        <Stack.Screen name="Quiz" component={Quiz} />
        <Stack.Screen name="Quiz2" component={Quiz2} />
        <Stack.Screen name="Piosenki" component={Piosenki} />
      </Stack.Navigator>
    );
}

export default Gry;