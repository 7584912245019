import React, { useEffect, useState } from 'react'
import { Platform, StyleSheet, ScrollView, View, Text, Image, Animated as RNAnimated, useWindowDimensions, TouchableOpacity, PixelRatio } from 'react-native';
import { Audio } from "expo-av";
import { useHeaderHeight } from '@react-navigation/stack'
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import { PREFIX } from "../Sanktuarium/stylesDomki";
import * as Obrazy from "../components/Obrazy";
import * as Font from "expo-font";
import { MaterialIcons } from "@expo/vector-icons";

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
    },
    content: {
        flex: 1,
        alignItems: "center",
    },
    utwor: {
      fontFamily: "CenturyGothic-Regular",
      fontSize: (Platform.OS === "web" ) ? 22 : 10,
    }
})

const PLAYLIST = [
  {
    nazwa: "1. Być ofiarnym",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/1.mp3"},
  },
  {
    nazwa: "2. Masz w sobie tyle słońca",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/2.mp3"},
  },
  {
    nazwa: "3. Idź, idź",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/3.mp3"},
  },
  {
    nazwa: "4. Jak stawiać kroki",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/4.mp3"},
  },
  {
    nazwa: "5. Promyczek dobra",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/5.mp3"},
  },
  {
    nazwa: "6. Ja Ciebie tak kocham",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/6.mp3"},
  },
  {
    nazwa: "7. Gdy drogi masz",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/7.mp3"},
  },
  {
    nazwa: "8. Gwiazdo morza",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/8.mp3"},
  },
  {
    nazwa: "9. Mam kolegów, koleżanki",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/9.mp3"},
  },
  {
    nazwa: "10. Magnificat",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/10.mp3"},
  },
  {
    nazwa: "11. Być apostołem",
    source: {uri: PREFIX + "piecpiaty.pl/assets/piosenki/11.mp3"},
  },
];

const Piosenki = ({navigation, route}) => {
    const windim = useWindowDimensions();
    const headerHeight = useHeaderHeight();
    const safeWidth = windim.width;
    const safeHeight = windim.height - headerHeight;
    const opacity = new RNAnimated.Value(0);
    const [playbackInstance, setPlaybackInstance] = useState(null);
    const [playIndex, setPlayIndex] = useState(0);
    const [isPlaying, setPlaying] = useState(false);

    function fontSize(size) {
      return Math.floor(size * windim.width / (Platform.OS === "web" ? 1600 : 1200));
    }
    
    useEffect(() => {
      //console.log("mount");
      (async () => {
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: false,
          staysActiveInBackground: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true,
          shouldDuckAndroid: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          playThroughEarpieceAndroid: false
        });
      })();
      (async () => {
        await Font.loadAsync({...MaterialIcons.font});
      })();
    }, []);

    useEffect(()=> async () => {
      //console.log("unload");
      if ( playbackInstance != null )
        await playbackInstance.unloadAsync();
    },[playbackInstance]);

    const _onPlayPausePressed = () => {
      if ( playbackInstance != null ) {
        if ( isPlaying ) {
          playbackInstance.pauseAsync();
        } else {
          playbackInstance.playAsync();
        }
      }
    };

    const _onStopPressed = () => {
      if ( playbackInstance != null ) {
        playbackInstance.stopAsync();
      }
    };

    const _onPlaybackStatusUpdate = status => {
      if ( status.isLoaded ) {
        setPlaying(status.isPlaying);
        /*
        this.setState({
          playbackInstancePosition: status.positionMillis,
          playbackInstanceDuration: status.durationMillis,
          shouldPlay: status.shouldPlay,
          isPlaying: status.isPlaying,
          isBuffering: status.isBuffering,
          rate: status.rate,
          muted: status.isMuted,
          volume: status.volume,
          loopingType: status.isLooping ? LOOPING_TYPE_ONE : LOOPING_TYPE_ALL,
          shouldCorrectPitch: status.shouldCorrectPitch
        });
        */
        if ( status.didJustFinish && ! status.isLooping ) {
          //console.log("next: ", (playIndex + 1) % PLAYLIST.length);
          _loadNewPlaybackInstance(true, (playIndex + 1) % PLAYLIST.length);
          //this._advanceIndex(true);
          //this._updatePlaybackInstanceForIndex(true);
        }
      } else {
        if ( status.error ) {
          console.warn("FATAL PLAYER ERROR: ", status.error);
        }
      }
    };

    async function _loadNewPlaybackInstance(playing, index) {
      try {
        setPlayIndex(index);
        const source = PLAYLIST[index].source;
        const initialStatus = {
          shouldPlay: playing,
          isMuted: false,
          isLooping: false,
        };
        const { sound, status } = await Audio.Sound.createAsync(
          source,
          initialStatus,
          _onPlaybackStatusUpdate,
        );
        setPlaybackInstance(sound);
      }
      catch ( error ) {
        console.warn(error);
      };
    }

    return (
      <Kratka style={styles.container}>
      <ScrollView>
          <View style={{ flexDirection: "row", marginTop: "4%", zIndex: 1 }}>
            <Image source={Obrazy.PiosenkiOkladka1} style={{ resizeMode: "contain", flex: 2, maxHeight: windim.height*0.6, transform: [{ rotate: "-10deg" }] }} />
            <View style={{ flex: 1, height: windim.width / 3, justifyContent: "center" }}>
              <View><Text style={[styles.utwor, { fontSize: fontSize(22) }]}>{isPlaying && PLAYLIST[playIndex].nazwa}</Text></View>
              <View style={{ flexDirection: "row" }}>
              <TouchableOpacity onPress={()=>_loadNewPlaybackInstance(true, (playIndex - 1 + PLAYLIST.length) % PLAYLIST.length)}><MaterialIcons name="skip-previous" size={fontSize(48)} color="black" /></TouchableOpacity>
              <TouchableOpacity onPress={()=>_loadNewPlaybackInstance(true, playIndex)}><MaterialIcons name="play-arrow" size={fontSize(48)} color="black" /></TouchableOpacity>
              <TouchableOpacity onPress={_onPlayPausePressed}><MaterialIcons name="pause" size={fontSize(48)} color="black" /></TouchableOpacity>
              <TouchableOpacity onPress={_onStopPressed}><MaterialIcons name="stop" size={fontSize(48)} color="black" /></TouchableOpacity>
              <TouchableOpacity onPress={()=>_loadNewPlaybackInstance(true, (playIndex + 1) % PLAYLIST.length)}><MaterialIcons name="skip-next" size={fontSize(48)} color="black" /></TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={{ backgroundColor: "#c9c9c9", flexDirection: "row", flex: 1, justifyContent: "space-evenly" }}>
            <View style={{ flex: 1 }}>
              <View style={{ height: windim.width / 3, alignItems: "center", justifyContent: "space-evenly", alignSelf: "flex-end" }}>
                {PLAYLIST.map((utwor, index) => 
                  <TouchableOpacity key={"utwor" + index} onPress={()=>{ _loadNewPlaybackInstance(true, index);}}><Text style={[styles.utwor, { fontSize: fontSize(22) }]}>{utwor.nazwa}</Text></TouchableOpacity>
                )}
              </View>
            </View>
            <Image source={Obrazy.PiosenkiOkladka2} style={{ resizeMode: "contain", flex: 1, maxHeight: windim.height*0.6 }} />
          </View>
          <View style={{ flexDirection: "row", marginBottom: "2%" }}>
            <Image source={Obrazy.PiosenkiOkladka3} style={{ resizeMode: "contain", flex: 2, maxHeight: windim.height, transform: [{ rotate: "10deg" }] }} />
            <View style={{ flex: 1, height: windim.width / 2 }} />
          </View>
          <Stopka />
      </ScrollView>
      </Kratka>
    );
}

export default Piosenki;