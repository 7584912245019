import React, { useEffect, useState } from 'react'
import { Platform, View, Image, Text, StyleSheet, TouchableOpacity, useWindowDimensions } from 'react-native';
import Animated, { interpolate, concat, Easing, useCode, Value, block, cond, eq, call, set, clockRunning, startClock, stopClock, timing, debug, not, multiply, Extrapolate, color, interpolateColors } from 'react-native-reanimated';
import { useClock, useValue, delay } from 'react-native-redash/lib/module/v1';
//import { SafeAreaView } from 'react-native-safe-area-context';
//import { useWindowDimensions } from '../components/Utility';
import { createStackNavigator } from '@react-navigation/stack';
import { headerStyles, LogoTitle } from '../components/LogoTitle';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import * as Obrazy from "../components/Obrazy";
import { ScrollView } from 'react-native-gesture-handler';

const pytania = [
  {
    pytanie: "Masz w pokoju ciastka. Po co?",
    odpowiedzi: [ "bo czasem jednym ciastkiem można tyle dobrego zrobić", "bo lubisz na nie patrzeć", "bo dostałaś od swojej siostry", ],
    prawidlowa: 0,
    
  },
  {
    pytanie: "Dokończ: czy dość jadasz?",
    odpowiedzi: [ "spróbuj sobie więcej odmawiać", "kup sobie czekoladę", "bo słabo wyglądasz", ],
    prawidlowa: 1,
  },
  {
    pytanie: "Pamiętaj, bądź słodka jak:",
    odpowiedzi: [ "miód", "Maryja", "czekolada", ],
    prawidlowa: 0,
  },
  {
    pytanie: "Życie w towarzystwie Jezusa jest:",
    odpowiedzi: [ "pokorne", "piękne", "słodkie", ],
    prawidlowa: 2,
  },
  {
    pytanie: "Święty to:",
    odpowiedzi: [ "nasz wzór", "przyjaciel", "człowiek pokorny", ],
    prawidlowa: 1,
  },
  {
    pytanie: "Bądź w rękach Jezusa niby…",
    odpowiedzi: [ "anioł", "pióro", "chorągiewka", ],
    prawidlowa: 1,
  },
  {
    pytanie: "…jest najlepszym lekarstwem na złe humory, na rozdrażnienie nerwowe.",
    odpowiedzi: [ "święte Boże wesele", "modlitwa", "spowiedź", ],
    prawidlowa: 0,
  },
  {
    pytanie: "Gdzie zawsze się spotykamy?",
    odpowiedzi: [ "w kaplicy", "w Jezusie", "w domu", ],
    prawidlowa: 1,
  },
  {
    pytanie: "Im ciemniej naokoło, tym jaśniej musi nam świecić światło…",
    odpowiedzi: [ "wiary", "nadziei", "miłości", ],
    prawidlowa: 1,
  },
  {
    pytanie: "Kochać bliźniego to znaczy chcieć mu życie rozjaśnić, osłodzić, …",
    odpowiedzi: [ "uprzyjemnić", "upięknić", "uświęcić", ],
    prawidlowa: 0,
  },
/*
  {
    pytanie: "",
    odpowiedzi: [ "", "", "", ],
    prawidlowa: ,
  },
*/
];

const opisWyniku = [
  "No cóż, nie da się ukryć, że z Urszulą się różnicie. Jeśli chcesz bardziej wczuć się w jej myślenie, zerknij raz jeszcze do jej tekstów. Są pełne uśmiechu i radości – bo przecież, jak mówiła święta Urszula, „pobożność nie czyni nas mrukami”!",
  "No cóż, nie da się ukryć, że z Urszulą się różnicie. Jeśli chcesz bardziej wczuć się w jej myślenie, zerknij raz jeszcze do jej tekstów. Są pełne uśmiechu i radości – bo przecież, jak mówiła święta Urszula, „pobożność nie czyni nas mrukami”!",
  "Hmm, to może spróbuj jeszcze raz? Ale wpierw przyjrzyj się naszej uśmiechniętej świętej: jak żyła, jak myślała, jakie miała poczucie humoru. A najlepiej zaprzyjaźnij się z Urszulką! To nie żart! Sama mówiła, że tacy przyjaciele są jak słodycze!",
  "Hmm, to może spróbuj jeszcze raz? Ale wpierw przyjrzyj się naszej uśmiechniętej świętej: jak żyła, jak myślała, jakie miała poczucie humoru. A najlepiej zaprzyjaźnij się z Urszulką! To nie żart! Sama mówiła, że tacy przyjaciele są jak słodycze!",
  "Pół na pół – to już dobrze, ale świętość to nie totolotek. I choć życie duchowe pełne jest przedziwnych zwrotów akcji, warto wiedzieć, jak przez nie przejść z uśmiechem. Bo przecież „uśmiech na twarzy mówi o szczęściu duszy”. To co? Wracamy do myśli świętej Urszuli?",
  "Pół na pół – to już dobrze, ale świętość to nie totolotek. I choć życie duchowe pełne jest przedziwnych zwrotów akcji, warto wiedzieć, jak przez nie przejść z uśmiechem. Bo przecież „uśmiech na twarzy mówi o szczęściu duszy”. To co? Wracamy do myśli świętej Urszuli?",
  "Całkiem nieźle się rozumiecie, ale jeszcze trochę brakuje do ideału. A przecież święta Urszula mówiła, że „trzeba stawiać sobie wysokie ideały”. Wyrusz z nią na przygodę! Możesz spotkać się z nią w jej tekstach, listach i słowach, które kierowała do swoich podopiecznych.",
  "Całkiem nieźle się rozumiecie, ale jeszcze trochę brakuje do ideału. A przecież święta Urszula mówiła, że „trzeba stawiać sobie wysokie ideały”. Wyrusz z nią na przygodę! Możesz spotkać się z nią w jej tekstach, listach i słowach, które kierowała do swoich podopiecznych.",
  "Ooo, co za intuicja! Poczucie humoru masz na pewno po świętej Urszuli, choć nie zaszkodzi modlić się o niego tak jak ona. Zatrzymaj się jeszcze chwilkę, by spędzić z nią i jej tekstami więcej czasu, a wkrótce będziecie nie do odróżnienia.",
  "Ooo, co za intuicja! Poczucie humoru masz na pewno po świętej Urszuli, choć nie zaszkodzi modlić się o niego tak jak ona. Zatrzymaj się jeszcze chwilkę, by spędzić z nią i jej tekstami więcej czasu, a wkrótce będziecie nie do odróżnienia.",
  "Wypisz wymaluj – święta Urszula! Jesteście jak dwie krople wody! Urszulka musi Ci być bardzo bliska, bo już myślisz i żartujesz tak jak ona. To fantastycznie! Bo przecież „osoba pogodna jest w życiu prawdziwym skarbem!”",
];

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        height: "100%",
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    pytanieContainer: {
        marginHorizontal: "10%",
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    pytanie: {
        textAlign: 'justify',
        fontFamily: 'CenturyGothic-Italic',
        fontWeight: 'normal',
        //fontStyle: 'italic',
        fontSize: ( Platform.OS === "web" ) ? 36 : 18,
        paddingVertical: "1%",
    },
    pytanieNr: {
      textAlign: 'justify',
      fontFamily: 'CenturyGothic-Bold',
      fontWeight: 'normal',
      //fontStyle: 'italic',
      fontSize: ( Platform.OS === "web" ) ? 36 : 18,
      paddingVertical: "1%",
    },
    wynik: {
      textAlign: 'justify',
      fontFamily: 'CenturyGothic-Regular',
      fontWeight: 'normal',
      //fontStyle: 'italic',
      fontSize: ( Platform.OS === "web" ) ? 36 : 18,
      paddingVertical: "1%",
    },
    bold: {
      fontFamily: 'CenturyGothic-Bold',
    },
});


const DURATION_IN = 1500;
const DURATION_OUT = 500;

function runTiming(clock, value, dest, duration) {
    const state = {
      finished: new Value(0),
      position: new Value(0),
      time: new Value(0),
      frameTime: new Value(0),
    };
  
    const config = {
      duration,
      toValue: new Value(dest),
      easing: Easing.inOut(Easing.ease),
    };
  
    return block([
      cond(
        not(clockRunning(clock)),
        [
          set(state.position, value),
          startClock(clock),
        ]
      ),
      // we run the step here that is going to update position
      timing(clock, state, config),
      // if the animation is over we stop the clock
      cond(state.finished, stopClock(clock)),
      // we make the block return the updated position
      state.position,
    ]);
}

const AnimatedText = Animated.createAnimatedComponent(Text);

const Quiz2 = ({navigation, route}) => {
  const windim = useWindowDimensions();
  const clock = useClock();
  const opacity = useValue(0);
  const parentOpacity = useValue(1);
  const fade = new Value(0);
  const [ nrPytania, setNrPytania ] = useState(0);
  const [ wynik, setWynik ] = useState({punkty: 0, udzielono: -1});
  const finish = useValue(0);
  const colorClock = useClock();
  const c1 = useValue(0);
  const c2 = useValue(0);
  const c3 = useValue(0);
  const c1v = useValue(0);
  const c2v = useValue(0);
  const c3v = useValue(0);

  function fontSize(size) {
    return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
  }

  const kolejnePytanie = ([f]) => {
    //console.log("kolejnePytanie", f);
    if ( ( nrPytania + 1 ) < pytania.length )
    {
      setNrPytania((nrPytania + 1 ) % pytania.length);
    }
    else
    {
      //console.log("wynik: ", wynik);
      setNrPytania(pytania.length);
    }
  };

  useCode(() => [
    cond(
      eq(c1v, 1),
      [
        set(c1, runTiming(colorClock, 0, 0.1, DURATION_OUT)),
        cond(not(clockRunning(colorClock)), delay(set(fade, 1), DURATION_IN))
      ]
    ),
    cond(
      eq(c1v, -1),
      [
        set(c1, runTiming(colorClock, 0.2, 0.3, DURATION_OUT)),
        cond(not(clockRunning(colorClock)), delay(set(fade, 1), DURATION_IN))
      ]
    ),
    cond(
      eq(c2v, 1),
      [
        set(c2, runTiming(colorClock, 0, 0.1, DURATION_OUT)),
        cond(not(clockRunning(colorClock)), delay(set(fade, 1), DURATION_IN))
      ]
    ),
    cond(
      eq(c2v, -1),
      [
        set(c2, runTiming(colorClock, 0.2, 0.3, DURATION_OUT)),
        cond(not(clockRunning(colorClock)), delay(set(fade, 1), DURATION_IN))
      ]
    ),
    cond(
      eq(c3v, 1),
      [
        set(c3, runTiming(colorClock, 0, 0.1, DURATION_OUT)),
        cond(not(clockRunning(colorClock)), delay(set(fade, 1), DURATION_IN))
      ]
    ),
    cond(
      eq(c3v, -1),
      [
        set(c3, runTiming(colorClock, 0.2, 0.3, DURATION_OUT)),
        cond(not(clockRunning(colorClock)), delay(set(fade, 1), DURATION_IN))
      ]
    ),
    // fadeout wywołany przez odpowiedź
    cond(
      eq(fade, 1),
      [
        set(parentOpacity, runTiming(clock, parentOpacity, 0, DURATION_OUT)),
        cond(eq(parentOpacity, 0), [call([finish], kolejnePytanie), set(finish, 1)]),
      ]
    ),
    // fadein przy nowym pytaniu
    cond(
      eq(fade, 0),
      set(opacity, runTiming(clock, opacity, 1, DURATION_IN)),
    ),
    // nowe pytanie, zresetuj wartości początkowe
    cond(eq(finish, 1), [
      set(finish, 0),
      set(opacity, 0),
      set(parentOpacity, 1),
      set(c1v, 0),
      set(c2v, 0),
      set(c3v, 0),
      set(c1, 0),
      set(c2, 0),
      set(c3, 0),
    ]),
  ],[nrPytania]); // odtworzenie kodu przy zmianie numeru pytania

  const transX1 = interpolate(opacity, { inputRange: [0, 0.5], outputRange: [windim.width, 0], extrapolate: Extrapolate.CLAMP});
  const transX2 = interpolate(opacity, { inputRange: [0.25, 0.75], outputRange: [windim.width, 0], extrapolate: Extrapolate.CLAMP});
  const transX3 = interpolate(opacity, { inputRange: [0.5, 1], outputRange: [windim.width, 0], extrapolate: Extrapolate.CLAMP});
  const rotate = concat(interpolate(opacity, { inputRange: [0, 0.25, 0.5, 0.75, 1], outputRange: [0, Math.PI / 2, Math.PI, Math.PI * 1.5, Math.PI *2]}), 'rad');
  const scale = interpolate(opacity, { inputRange: [0, 1], outputRange: [0, 1] });
  const color1 = interpolateColors(c1, { inputRange: [0, 0.1, 0.2, 0.3], outputColorRange: ["black", "green", "black", "red"]});
  const color2 = interpolateColors(c2, { inputRange: [0, 0.1, 0.2, 0.3], outputColorRange: ["black", "green", "black", "red"]});
  const color3 = interpolateColors(c3, { inputRange: [0, 0.1, 0.2, 0.3], outputColorRange: ["black", "green", "black", "red"]});

  const odpowiedz = (nr) => {
    //console.log("odpowiedz na pytanie ", nrPytania);
    if ( wynik.udzielono == nrPytania )
    {
      //console.log("już udzielono odpowiedzi");
      return;
    }
    //fade.setValue(1);
    if ( nr == pytania[nrPytania].prawidlowa )
    {
      switch ( nr )
      {
        case 0:
          c1v.setValue(1);
          break;
        case 1:
          c2v.setValue(1);
          break;
        case 2:
          c3v.setValue(1);
          break;
      }
      //console.log("odpowiedź prawidłowa");
      setWynik({punkty: wynik.punkty + 1, udzielono: nrPytania});
    }
    else
    {
      switch ( nr )
      {
        case 0:
          c1v.setValue(-1);
          break;
        case 1:
          c2v.setValue(-1);
          break;
        case 2:
          c3v.setValue(-1);
          break;
      }
      switch ( pytania[nrPytania].prawidlowa )
      {
        case 0:
          c1v.setValue(1);
          break;
        case 1:
          c2v.setValue(1);
          break;
        case 2:
          c3v.setValue(1);
          break;
      }
      //console.log("odpowiedź błędna");
      setWynik({punkty: wynik.punkty, udzielono: nrPytania});
    }
  };

  return (
    <Kratka style={[styles.container, { backgroundColor: "white" }]}>
      <ScrollView style={{ width: "100%" }} contentContainerStyle={{ flexGrow: 1, justifyContent: 'center', alignItems: "center" }}>
        <View style={{ flexGrow: 1 }} />
        { ( nrPytania < pytania.length ) &&
        <Animated.View style={[styles.pytanieContainer, { opacity: parentOpacity }]}>
          <Animated.View style={{ opacity }}>
            <Text style={[styles.pytanieNr, { fontSize: fontSize(30) }]}>Pytanie {nrPytania + 1}.</Text>
            <Text style={[styles.pytanie, { fontSize: fontSize(30) }]}>{pytania[nrPytania].pytanie}</Text>
          </Animated.View>
          <Animated.View style={{transform: [{ translateX: transX1 }] }}>
            <TouchableOpacity onPress={() => odpowiedz(0)}><AnimatedText style={[styles.pytanie, { fontSize: fontSize(30), color: color1 }]}>a) {pytania[nrPytania].odpowiedzi[0]}</AnimatedText></TouchableOpacity>
          </Animated.View>
          <Animated.View style={{transform: [{ translateX: transX2 }] }}>
            <TouchableOpacity onPress={() => odpowiedz(1)}><AnimatedText style={[styles.pytanie, { fontSize: fontSize(30), color: color2 }]}>b) {pytania[nrPytania].odpowiedzi[1]}</AnimatedText></TouchableOpacity>
          </Animated.View>
          <Animated.View style={{transform: [{ translateX: transX3 }] }}>
            <TouchableOpacity onPress={() => odpowiedz(2)}><AnimatedText style={[styles.pytanie, { fontSize: fontSize(30), color: color3 }]}>c) {pytania[nrPytania].odpowiedzi[2]}</AnimatedText></TouchableOpacity>
          </Animated.View>
        </Animated.View>
        }
        { ( nrPytania == pytania.length ) &&
        <View style={{ marginHorizontal: "10%", alignItems: 'center', justifyContent: 'center' }}>
          <AnimatedText style={[{ opacity, transform: [{ scale }, { rotate }] }, styles.wynik, { fontSize: fontSize(40) }]}>Twój wynik: <Text style={styles.bold}>{wynik.punkty} / {pytania.length}</Text></AnimatedText>
          <AnimatedText style={[{ opacity }, styles.wynik, { fontSize: fontSize(30) }]}>{opisWyniku[wynik.punkty]}</AnimatedText>
        </View>
          // style={{ opacity, transform: [{ scale }, { rotate }] }}
        }
        <View style={{ flexGrow: 1 }} />
        <Stopka />
      </ScrollView>
    </Kratka>
  );
}

export default Quiz2;