import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D3/";
const PREVIEW = O.D3;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D3_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 922, height: 975},
      opis: 'Budynek pralni, rok 1929',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D3_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Marty, obecnie w przebudowie',
  },
];
export const goscie = [
  {
    id: 201,
    preview: PREVIEW.D3_g1p,
    zdjecie: {uri: PATH + "g1.jpg", width: 1201, height: 875},
    opis: 'Z kard. Edmundem Dalborem',
  },
  {
    id: 202,
    preview: PREVIEW.D3_g2p,
    zdjecie: {uri: PATH + "g2.jpg", width: 1158, height: 1058},
    opis: 'Z kard. Edmundem Dalborem i miss Cawood',
  },
  {
    id: 203,
    preview: PREVIEW.D3_g3p,
    zdjecie: {uri: PATH + "g3.jpg", width: 496, height: 840},
    opis: 'Powitanie Prezydenta Mościckiego',
  },
  {
    id: 204,
    preview: PREVIEW.D3_g4p,
    zdjecie: {uri: PATH + "g4.jpg", width: 1480, height: 987},
    opis: 'Z Prezydentem RP Ignacym Mościckim, 1927',
  },
  {
    id: 205,
    preview: PREVIEW.D3_g5p,
    zdjecie: {uri: PATH + "g5.jpg", width: 1392, height: 1024},
    opis: 'Po uroczystosciach, Pniewy 1929',
  },
  {
    id: 206,
    preview: PREVIEW.D3_g6p,
    zdjecie: {uri: PATH + "g6.jpg", width: 1920, height: 1284},
    opis: 'Z bp. Stanisławem Łukomskim',
  },
  {
    id: 207,
    preview: PREVIEW.D3_g7p,
    zdjecie: {uri: PATH + "g7.jpg", width: 909, height: 1267},
    opis: 'Bp Stanisław Łukomski w Pniewach, 1936',
  },
  {
    id: 208,
    preview: PREVIEW.D3_g8p,
    zdjecie: {uri: PATH + "g8.jpg", width: 1072, height: 1539},
    opis: 'Z kard. Augustem Hlondem w Pniewach',
  },
  {
    id: 209,
    preview: PREVIEW.D3_g9p,
    zdjecie: {uri: PATH + "g9.jpg", width: 576, height: 610},
    opis: 'Z kard. Augustem Hlondem, IV 1937',
  },
  {
    id: 210,
    preview: PREVIEW.D3_g10p,
    zdjecie: {uri: PATH + "g10.jpg", width: 553, height: 405},
    opis: 'Z ks. Prymasem i s. Prądzyńską, 1937',
  },
  {
    id: 211,
    preview: PREVIEW.D3_g11p,
    zdjecie: {uri: PATH + "g11.jpg", width: 1445, height: 860},
    opis: 'Z bp. Kazimierzem Bukrabą na cmentarzu pniewskim, 1937',
  },
];
