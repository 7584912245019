import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D9/";
const PREVIEW = O.D9;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D9_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1203, height: 839},
      opis: 'Dom św. Anieli, 1920',
    },
    {
      id: 2,
      preview: PREVIEW.D9_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 403, height: 417},
      opis: 'Matka Urszula przed domem św. Anieli',
    },
    {
      id: 3,
      preview: PREVIEW.D9_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 561, height: 555},
      opis: 'Matka Urszula przed domem św. Anieli',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D9_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: '',
  },
  {
    id: 102,
    preview: PREVIEW.D9_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1440},
    opis: '',
  },
  {
    id: 103,
    preview: PREVIEW.D9_a3p,
    zdjecie: {uri: PATH + "a3.jpg", width: 1440, height: 1920},
    opis: 'Kaplica w domu św. Anieli',
  },
];
