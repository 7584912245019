import React, { useState, useRef, useEffect } from 'react'
import { Platform, StyleSheet, View, ScrollView, Image, ImageBackground, Animated as RNAnimated, Text, TouchableOpacity, TouchableWithoutFeedback, PixelRatio, useWindowDimensions } from 'react-native';
import { Audio, Video } from 'expo-av';
import { Svg, Path } from 'react-native-svg';
//import { SafeAreaView } from 'react-native-safe-area-context';
//import { useWindowDimensions } from '../components/Utility';
//import { createStackNavigator } from '@react-navigation/stack';
import { headerStyles, LogoTitle } from '../components/LogoTitle';
import { useFocusEffect } from '@react-navigation/native';
import { createSharedElementStackNavigator, SharedElement } from 'react-navigation-shared-element';
import Zdjecie from './Zdjecie';
//import { zdjecia } from './zdjeciaUrszula';
//import { Img } from '../components/Img';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import FullWidthImage from 'react-native-fullwidth-image';
import * as Obrazy from "../components/Obrazy";
import { LosowaMysl } from '../Mysl/Mysl';
import { PREFIX } from "../Sanktuarium/stylesDomki";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
  },
  contentText: {
    textAlign: "justify",
    fontFamily: "CenturyGothic-Regular",
    fontSize: ((Platform.OS === "web" ) ? 22 : 10) * PixelRatio.getFontScale(),
  },
  headerText: {
    fontFamily: "Sacramento_400Regular",
    fontSize: ((Platform.OS === "web" ) ? 46 : 22) * PixelRatio.getFontScale(),
    paddingVertical: "1%",
  },
  kalendarium: {
    fontFamily: "CenturyGothic-Regular",
    fontSize: ((Platform.OS === "web" ) ? 22 : 10) * PixelRatio.getFontScale(),
    paddingHorizontal: "20%",
    paddingVertical: "2%",
    textAlign: "justify",
  },
  kalendariumCzas: {
    fontFamily: "CenturyGothic-Bold",
    fontSize: ((Platform.OS === "web" ) ? 22 : 10) * PixelRatio.getFontScale(),
  },
  kalendariumRow: {
    flexDirection: "row",
    justifyContent: "center"
  },
  zdjecie: {
    resizeMode: 'contain',
    margin: 5,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
  arrow: {
    margin: 5,
  },
  kino: {
    ...Platform.select({
      "native": {
        width: '100%',
        height: undefined,
        aspectRatio: 1920 / 757,
      },
      "web": {
        width: 1920,
        height: 757,
      },
    })
  },
  telefon: {
    ...Platform.select({
      "native": {
        width: '100%',
        height: undefined,
        aspectRatio: 1920 / 530,
      },
      "web": {
        width: 1920,
        height: 530,
      },
    })
  },
});

const kalendarium = [
  {
    time: '17 kwietnia 1865',
    title: 'Narodziny',
    description: 'Julia przychodzi na świat w Loosdorf w Austrii jako drugie z siedmiorga dzieci Antoniego i Józefiny Salis-Zizers.',
  },
  {
    time: '1883',
    title: 'Przeprowadzka',
    description: 'Rodzina Ledóchowskich przenosi się do Lipnicy Murowanej, blisko Krakowa.',
  },
  {
    time: '1886',
    title: 'Wstąpienie do klasztoru',
    description: 'Julia wstępuje do klasztoru sióstr urszulanek w Krakowie, otrzymuje imię Urszula.',
  },
  {
    time: '1907',
    title: 'Wyjazd do Rosji',
    description: 'Z błogosławieństwem papieża Piusa X wyjeżdża do Petersburga.',
  },
  {
    time: '1908',
    title: 'Autonomia',
    description: 'Powiększająca się wspólnota sióstr, żyjąca w ukryciu staje się autonomicznym domem urszulanek w Petersburgu, a m. Urszula jego przełożoną.',
  },
  {
    time: '1910',
    title: 'Nowy dom zakonny',
    description: 'Nad Zatoką Fińską powstaje dom dla wspólnoty oraz – realizujące idee pedagogiczne m. Urszuli – gimnazjum z internatem dla dziewcząt.',
  },
  {
    time: '1914',
    title: 'Wyjazd z Rosji',
    description: 'Wybuch wojny powoduje wydalenie m. Urszuli z Rosji. Zatrzymuje się w krajach skandynawskich.',
  },
  {
    time: '1920',
    title: 'Nowe zgromadzenie',
    description: 'Petersburska wspólnota urszulanek wraca do wolnej Polski, do Pniew koło Poznania, a wkrótce potem otrzymuje od Stolicy Świętej pozwolenie na przekształcenie się w Zgromadzenie Urszulanek Serca Jezusa Konającego (szare urszulanki).',
  },
  {
    time: '29 maja 1939',
    title: 'Narodziny dla nieba',
    description: 'Gdy umiera w Rzymie, ludzie mówią, że zmarła święta…',
  },
  {
    time: '20 czerwca 1983',
    title: 'Beatyfikacja',
    description: 'Papież Jan Paweł II beatyfikuje matkę Urszulę w Poznaniu.',
  },
  {
    time: '1989',
    title: 'Przeniesienie ciała',
    description: 'Zachowane od zniszczenia ciało bł. Urszuli zostaje przewiezione z Rzymu do Pniew i złożone w kaplicy domu macierzystego.',
  },
  {
    time: '18 maja 2003',
    title: 'Kanonizacja',
    description: 'Papież Jan Paweł II dokonuje kanonizacji matki Urszuli Ledóchowskiej w Rzymie.',
  }
];

const Kalendarium = () => {
  const windim = useWindowDimensions();

  function fontSize(size) {
    return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
  }

  const kstyles = StyleSheet.create({
    headerText: {
      fontFamily: "Sacramento_400Regular",
      fontSize: fontSize(46),
      paddingVertical: "1%",
      alignSelf: "center",
    },
    tekst: {
      fontFamily: "CenturyGothic-Regular",
      fontSize: fontSize(22),
      paddingHorizontal: "20%",
      paddingVertical: "2%",
      textAlign: "justify",
    },
    czas: {
      fontFamily: "CenturyGothic-Bold",
      fontSize: fontSize(22),
    },
    row: {
      flexDirection: "row",
      justifyContent: "center"
    },
    obraz: {
      marginVertical: "2%",
      marginHorizontal: "1%",
      width: Math.floor(windim.width / 4.5),
      height: Math.floor(windim.width / 4.5),
      resizeMode: "contain"
    },
  });
  return (
    <View>
      <Text style={kstyles.headerText}>Kalendarium</Text>
      <Text style={kstyles.tekst}>
        <Text style={kstyles.czas}>{kalendarium[0].time}</Text> - {kalendarium[0].description}
      </Text>
      <View style={kstyles.row}>
        <Image source={Obrazy.Tata} style={kstyles.obraz} />
        <Image source={Obrazy.UlaDziecko} style={kstyles.obraz} />
        <Image source={Obrazy.Mama} style={kstyles.obraz} />
      </View>
      <Text style={kstyles.tekst}>
        <Text style={kstyles.czas}>{kalendarium[1].time}</Text> - {kalendarium[1].description}{"\n\n"}
        <Text style={kstyles.czas}>{kalendarium[2].time}</Text> - {kalendarium[2].description}
      </Text>
      <View style={kstyles.row}>
        <Image source={Obrazy.UlaUrszulankaCzarna} style={kstyles.obraz} />
      </View>
      <Text style={kstyles.tekst}>
        <Text style={kstyles.czas}>{kalendarium[3].time}</Text> - {kalendarium[3].description}{"\n\n"}
        <Text style={kstyles.czas}>{kalendarium[4].time}</Text> - {kalendarium[4].description}{"\n\n"}
        <Text style={kstyles.czas}>{kalendarium[5].time}</Text> - {kalendarium[5].description}{"\n\n"}
        <Text style={kstyles.czas}>{kalendarium[6].time}</Text> - {kalendarium[6].description}{"\n\n"}
        <Text style={kstyles.czas}>{kalendarium[7].time}</Text> - {kalendarium[7].description}
      </Text>
      <View style={kstyles.row}>
        <Image source={Obrazy.UlaPniewy} style={kstyles.obraz} />
      </View>
      <Text style={kstyles.tekst}>
        <Text style={kstyles.czas}>{kalendarium[8].time}</Text> - {kalendarium[8].description}{"\n\n"}
        <Text style={kstyles.czas}>{kalendarium[9].time}</Text> - {kalendarium[9].description}{"\n\n"}
        <Text style={kstyles.czas}>{kalendarium[10].time}</Text> - {kalendarium[10].description}
      </Text>
      <View style={kstyles.row}>
        <Image source={Obrazy.UlaSpiaca} style={kstyles.obraz} />
      </View>
      <Text style={kstyles.tekst}>
        <Text style={kstyles.czas}>{kalendarium[11].time}</Text> - {kalendarium[11].description}
      </Text>
    </View>
  );
}

const UrszulaScreen = ({navigation, route}) => {
  const windim = useWindowDimensions();
  const [cytat, setCytat] = useState(null);
  const opacity = React.useMemo(() => new RNAnimated.Value(0), []);
  const [ videoHeight, setVideoHeight ] = useState(0);

  /*
  console.log("windim.width: ", windim.width);
  console.log("PixelRatio.get: ", PixelRatio.get());
  console.log("PixelRatio.getFontScale: ", PixelRatio.getFontScale());
  */

  function fontSize(size) {
    return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
  }

  const videoRef = useRef(null);

  useEffect(() => {
    //console.log("mount");
    (async ()=>{
      await Audio.setAudioModeAsync({
        allowsRecordingIOS: false,
        staysActiveInBackground: false,
        interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        playsInSilentModeIOS: true,
        shouldDuckAndroid: true,
        interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
        playThroughEarpieceAndroid: false
    });  
    })();
  }, []);

  useEffect(() => async () => {
    if ( videoRef.current != null )
      await videoRef.current.stopAsync();
    /*
    if ( videoRef.current != null )
      await videoRef.current.unloadAsync();
    */
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      // Do something when the screen is focused
      return async () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
        if ( videoRef.current != null )
          await videoRef.current.stopAsync();
        /*
        if ( videoRef.current != null )
          await videoRef.current.unloadAsync();
        */
      };
    }, [])
  );

  const [ kwidth, setKwidth ] = useState(0);
  const [ kheight, setKheight ] = useState(0);
  return (
    <Kratka>
    <ScrollView maximumZoomScale={3} minimumZoomScale={1}>
{/*
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 3, backgroundColor: "lightblue" }}>
            <View style={{ paddingLeft: "10%" }}>
            <Text style={[styles.headerText, { fontSize: fontSize(46) }]}>Pogodna święta</Text>
            <Text style={[styles.contentText, { fontSize: fontSize(22) }]}>
            JAK ZOSTAĆ ŚWIĘTYM? NIC PROSTSZEGO! WYSTARCZY BYĆ DOBRYM I JAK NAJCZĘŚCIEJ SIĘ UŚMIECHAĆ. TAK JAK TA MAŁA HRABINA! POZNAJCIE ULĘ, URSZULANKĘ NUMER JEDEN!</Text>
            <Text style={[styles.contentText, { fontSize: fontSize(22) }]}>ULA CZY JULIA?</Text>
            <Text style={[styles.contentText, { fontSize: fontSize(22) }]}>Julia (bo takie imię otrzymała na chrzcie świętym) urodziła się w Austrii, w poniedziałek wielkanocny 1865 r. Jej mama Józefina była Szwajcarką, a tata Antoni – Polakiem. Rodzina Julii była bardzo szczęśliwa. Miała rodzeństwo, z którym łączyła ją wielka miłość.</Text>
            <Text style={[styles.contentText, { fontSize: fontSize(22) }]}>Julia bardzo chętnie chodziła do szkoły, uczyła się języków obcych i obdarzona była wieloma talentami.</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1, marginBottom: "-100%" }}>
                <Image source={Obrazy.Ula} resizeMode="contain" style={{ flex: 0, width: "100%", height: undefined, aspectRatio: 600 / 1580 }} />
              </View>
              <View style={{ flex: 1 }}>
                <Text style={[styles.contentText, { paddingTop: "2%", fontSize: fontSize(22) }]}>PROMYK SŁOŃCA{"\n"}
                Była bardzo żywa, wesoła, miała miłe usposobienie i uroczy uśmiech, dlatego bliscy nazywali ją Promykiem Słonecznym. Osiemnaste urodziny Julia obchodziła już w Polsce. Rodzina zamieszkała w Lipnicy Murowanej, malowniczo położonej miejscowości niedaleko Krakowa.</Text>
              </View>
            </View>
          </View>
          <View style={{ flex: 2, flexDirection: "column", marginBottom: "-100%" }}>
            <Image source={Obrazy.List1} resizeMode="contain" style={{ flex: 0, width: "100%", height: undefined, aspectRatio: 650 / 713, marginBottom: "-35%" }} />
            <Image source={Obrazy.Zdjecie1} resizeMode="contain" style={{ flex: 0, width: "90%", height: undefined, aspectRatio: 650 / 576 }} />
          </View>
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1.7 }} />
          <View style={{ flex: 2 }}>
            <Text style={[styles.contentText, { paddingTop: "2%", fontSize: fontSize(22) }]}>URSZULA{"\n"}
            Gdy miała 21 lat wstąpiła do klasztoru w Krakowie, aby innym dać Boga! Tam otrzymała nowe imię – URSZULA. Była wspaniałą siostrą, bardzo dobrą nauczycielką i kochaną wychowawczynią, po 21 latach życia w krakowskim klasztorze, wyjechała do Petersburga, skąd po wybuchu I wojny światowej, musiała udać się do krajów skandynawskich, gdzie współpracowała z Henrykiem Sienkiewiczem w Generalnym Komitecie Pomocy Ofiarom Wojny w Polsce. To również dzięki pomocy przyjaciół ze Skandynawii udało się jej kupić dom w Pniewach.</Text>
          </View>
          <View style={{ flex: 3.5 }}>
              <Image source={Obrazy.Zdjecie2} resizeMode="contain" style={{ position: "absolute", flex: 0, width: "50%", height: undefined, aspectRatio: 470 / 587 }} />
              <Image source={Obrazy.List2} resizeMode="contain" style={{ position: "absolute", flex: 0, width: "70%", height: undefined, aspectRatio: 650 / 585 }} />
          </View>
        </View>
*/}

        <View style={{ paddingLeft: "8%", paddingRight: "36%" }}>
          <View style={{ position: "absolute", right: 0, top: 0, marginRight: "2%" }}>
            <Image source={Obrazy.List1} resizeMode="contain" style={{ width: Math.floor(windim.width / 3), height: Math.floor(windim.width / 3 * 650 / 713) }} />
            <Image source={Obrazy.Zdjecie1} resizeMode="contain" style={{ marginLeft: "2%", marginTop: "-40%", width: Math.floor(windim.width / 3 * 0.8), height: Math.floor(windim.width / 3 * 0.8 * 650 / 576) }} />
          </View>
          <Text style={[styles.headerText, { fontSize: fontSize(46) }]}>Pogodna święta</Text>
          <Text style={[styles.contentText, { fontSize: fontSize(22) }]}>
          JAK ZOSTAĆ ŚWIĘTYM? NIC PROSTSZEGO! WYSTARCZY BYĆ DOBRYM I JAK NAJCZĘŚCIEJ SIĘ UŚMIECHAĆ. TAK JAK TA MAŁA HRABINA! POZNAJCIE ULĘ, URSZULANKĘ NUMER JEDEN!{"\n\n"}
          ULA CZY JULIA?{"\n"}
          Julia (bo takie imię otrzymała na chrzcie świętym) urodziła się w Austrii, w poniedziałek wielkanocny 1865 r. Jej mama Józefina była Szwajcarką, a tata Antoni – Polakiem. Rodzina Julii była bardzo szczęśliwa. Miała rodzeństwo, z którym łączyła ją wielka miłość.{"\n"}
          Julia bardzo chętnie chodziła do szkoły, uczyła się języków obcych i obdarzona była wieloma talentami.
          </Text>
        </View>
        <View>
          <Image source={Obrazy.Ula} resizeMode="contain" style={{ position: "absolute", top: 0, left: 0, width: Math.floor(windim.width / 3 * 0.8), height: Math.floor(windim.width / 3 * 0.8 * 1080 / 410) }} />
          <Text style={[styles.contentText, { paddingLeft: "28%", paddingTop: "2%", paddingRight: "40%", fontSize: fontSize(22) }]}>PROMYK SŁOŃCA{"\n"}
          Była bardzo żywa, wesoła, miała miłe usposobienie i uroczy uśmiech, dlatego bliscy nazywali ją Promykiem Słonecznym. Osiemnaste urodziny Julia obchodziła już w Polsce. Rodzina zamieszkała w Lipnicy Murowanej, malowniczo położonej miejscowości niedaleko Krakowa.</Text>
        </View>
        <View>
          <View style={{position:"absolute",right:0, paddingTop: "2%", flexDirection: "row", width: windim.width / 3 + windim.width / 3.5 }}>
            <Image source={Obrazy.Zdjecie2} resizeMode="contain" style={{ right: -windim.width / 10.5, zIndex: 1, width: windim.width / 3, height: windim.width / 3 * 470 / 587 }} />
            <Image source={Obrazy.List2} resizeMode="contain" style={{ width: windim.width / 3.5, height: windim.width / 3.5 * 650 / 585 }} />
          </View>
          <Text style={[styles.contentText, { paddingLeft: "22%", paddingTop: "2%", paddingRight: "48%", fontSize: fontSize(22) }]}>URSZULA{"\n"}
          Gdy miała 21 lat wstąpiła do klasztoru w Krakowie, aby innym dać Boga! Tam otrzymała nowe imię – URSZULA. Była wspaniałą siostrą, bardzo dobrą nauczycielką i kochaną wychowawczynią, po 21 latach życia w krakowskim klasztorze, wyjechała do Petersburga, skąd po wybuchu I wojny światowej, musiała udać się do krajów skandynawskich, gdzie współpracowała z Henrykiem Sienkiewiczem w Generalnym Komitecie Pomocy Ofiarom Wojny w Polsce. To również dzięki pomocy przyjaciół ze Skandynawii udało się jej kupić dom w Pniewach.</Text>
        </View>
        <View>
          <Image source={Obrazy.Piec} resizeMode="contain" style={{ width: Math.floor(windim.width / 3 * 0.6), height: Math.floor(windim.width / 3 * 0.6 * 648 / 400), position: "absolute", left: "10%", marginTop: "2%" }} />
          <Text style={[styles.contentText, { paddingLeft: "30%", paddingTop: "4%", paddingRight: "16%", fontSize: fontSize(22) }]}>PNIEWY{"\n"}
          W 1920 roku matka Urszula wróciła do odrodzonej Ojczyzny, a wraz z nią przyjechały siostry i dzieci – polskie sieroty. Przyjechały prosto do wymarzonego kompleksu w Pniewach. Ale… Pniewy nie wyglądały wtedy tak bajkowo! Widok poniszczonych budynków i pustych, ubogich pomieszczeń sprawił, że Urszula krzyknęła: „Cztery kąty i piec piąty!” Ale wkrótce widok Pniew zmienił się nie do poznania! A same siostry założyły nowe zgromadzenie zakonne – Urszulanki Serca Jezusa Konającego.</Text>
        </View>
        <View style={{ marginTop: "8%" }}>
          <Text style={[styles.headerText, { marginLeft: "35%", fontSize: fontSize(46), transform: [{ rotate: "10deg" }]}]}>„Cztery kąty i piec piąty!”</Text>
        </View>
        <View style={{ marginTop: "2%", marginBottom: "60%" }}>
          <Image source={Obrazy.ListZestaw} resizeMode="contain" style={{ width: windim.width, height: windim.width * 1641 / 1920, position: "absolute", bottom: -windim.width * 1141 / 1920, left: 0 }} />
          <Text style={[styles.contentText, { paddingLeft: "8%", paddingRight: "40%", marginTop: "5%", fontSize: fontSize(22) }]}>DO NIEBA!{"\n"}
          Matka Urszula zmarła w Rzymie 29 maja 1939 roku, a Jan Paweł II w roku 2003 ogłosił ją świętą! Ponieważ urszulanki chciały mieć ją blisko, postanowiły w 1989 roku przewieźć jej relikwie do Pniew. Jakie było zdumienie wszystkich, gdy po otworzeniu trumny okazało się, że jej ciało nie uległo rozkładowi. Urszula uśmiechnięta patrzy dziś na nas z nieba i patronuje nie tylko Pniewom, ale też uczniom i ich nauczycielom. Mrugnijcie do niej przed trudnym testem!</Text>
        </View>
        { Platform.OS === 'web' &&
        <View>
          <FullWidthImage source={Obrazy.Telefon} style={styles.telefon} />
          <TouchableOpacity onPress={()=>setCytat(LosowaMysl())} style={{ position: "absolute", top: "20%", left: "35%", width: windim.width * 0.1, height: windim.width * 0.1 }}><Image source={Obrazy.TelefonPrzycisk} style={{ flex: 1, resizeMode: "contain" }} /></TouchableOpacity>
          { !! cytat && <View style={{ position: "absolute", left: "45%", top: "10%", right: 0, bottom: "16%", marginHorizontal: "1%" }}>
            <Svg width="100%" height="100%" viewBox="-2 -2 317 7">
              <Path
                stroke="black" strokeWidth="2" fill="none" strokeLinecap="round"
                d="M0,.35A64.83,64.83,0,0,1,21.84,1.79c11,2.85,37.26.23,43.44-.24s24.64-1.76,30-.48,19.55,1.08,23.28,1,14.67-1,19-.24S166.63.85,169,.83,182,.1,187.2,1.55,216.31,2.86,221,2.75s21-1.26,24.24-1.92,14.72-.8,18,0,14,1,22.08,1,28.08-.72,28.08-.72"
              />
            </Svg>
            <Text style={{ textAlign: "center", fontFamily: "CenturyGothic-Bold", fontSize: fontSize(30), paddingVertical: "2%" }}>{cytat}</Text>
            <Svg width="100%" height="100%" viewBox="-2 -2 317 7">
              <Path
                stroke="black" strokeWidth="2" fill="none" strokeLinecap="round"
                d="M0,.35A64.39,64.39,0,0,1,21.84,1.8c11,2.86,37.26.23,43.44-.24s24.64-1.77,30-.49,19.55,1.09,23.28,1,14.67-1,19-.24S166.63.85,169,.83,182,.1,187.2,1.56,216.31,2.88,221,2.76s21-1.27,24.24-1.93,14.72-.8,18,0,14,1,22.08,1,28.08-.73,28.08-.73"
              />
            </Svg>
          </View>}
        </View>
        }
        { Platform.OS !== 'web' &&
        <View>
          <Image source={Obrazy.Telefon} style={styles.telefon} />
          <TouchableOpacity onPress={()=>setCytat(LosowaMysl())} style={{ position: "absolute", top: "20%", left: "35%", width: windim.width * 0.1, height: windim.width * 0.1 }}><Image source={Obrazy.TelefonPrzycisk} style={{ width: windim.width * 0.1, height: windim.width * 0.1, resizeMode: "contain" }} /></TouchableOpacity>
          { !! cytat && <View style={{ position: "absolute", left: "45%", top: "10%", right: 0, bottom: "16%", marginHorizontal: "1%", justifyContent: "center" }}>
            <Text style={{ textAlign: "center", fontFamily: "CenturyGothic-Bold", fontSize: fontSize(30)  }}>{cytat}</Text>
            </View>}
        </View>
        }
        <Kalendarium />
        { Platform.OS === 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <FullWidthImage source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View onContextMenu={(e)=>{e.preventDefault();}} style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/Ula.mp4", width: 1920, height: 1080 }}
              onLoad={()=>{
                document.querySelector("video").controlsList = "nodownload";
                RNAnimated.timing(opacity, {
                  toValue: 1,
                  ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }]}
            />
          </RNAnimated.View>
        </View>
        }
        { Platform.OS !== 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <Image source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/Ulam.mp4", width: 1280, height: 720 }}
              onLoad={()=>{RNAnimated.timing(opacity, {
                toValue: 1,
                ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }]}
            />
          </RNAnimated.View>
        </View>
        }
        <Stopka />
    </ScrollView>
    </Kratka>
  );
}

const Stack = createSharedElementStackNavigator();

const Urszula = ({navigation, route}) => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerBackTitleVisible: false,
          headerStyle: headerStyles.header,
          headerTintColor: 'white',
          headerTitleStyle: headerStyles.headerTitle,
          headerTitleContainerStyle: headerStyles.headerContainer,
          headerTitle: props => <LogoTitle {...props} navigation={navigation}>Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach</LogoTitle>,
          title: "Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach",
        }}>
        <Stack.Screen name="UrszulaScreen" component={UrszulaScreen} />
        <Stack.Screen name="Zdjecie" component={Zdjecie} options={{ /*animationEnabled: true,*/ }}
          sharedElements={(route, otherRoute, showing) => {
            const zdjecie = route.params.zdjecie;
            return [{
              id: zdjecie.id,
              //animation: 'fade',
              resize: 'auto',
              //align: 'center-bottom',
            }];
          }}
        />
      </Stack.Navigator>
    );
}

export default Urszula;

/*
        <View style={styles.galeria}>
          <Svg width="50" height="50" viewBox="0 0 70 100" style={styles.arrow}>
            <Path
              opacity={0.6}
              fill="black"
              strokeWidth={1}
              d="M70 0L0 50L70 100Z"
              />
          </Svg>
          <ScrollView horizontal={true}>
            {zdjecia.map((zdjecie, i) => 
            <TouchableWithoutFeedback key={"z"+i} onPress={() => navigation.push("Zdjecie", { zdjecie })}>
              <SharedElement id={zdjecie.id}>
                <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={[ styles.zdjecie, { width: 450 / PixelRatio.get() * zdjecie.width/zdjecie.height , height: 450 / PixelRatio.get() } ]} />
              </SharedElement>
            </TouchableWithoutFeedback>
            )}
          </ScrollView>
          <Svg width="50" height="50" viewBox="0 0 70 100" style={styles.arrow}>
            <Path
              opacity={0.6}
              fill="black"
              strokeWidth={1}
              d="M0 0L70 50L0 100Z"
              />
          </Svg>
        </View>
*/