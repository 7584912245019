import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D7/";
const PREVIEW = O.D7;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D7_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 581, height: 482},
      opis: 'Z chłopcami z kolegium w Pniewach, r. 1925',
    },
    {
      id: 2,
      preview: PREVIEW.D7_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 1521, height: 1125},
      opis: 'W domu dziecka w Pniewach z bp. Łukomskim',
    },
    {
      id: 3,
      preview: PREVIEW.D7_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 782, height: 539},
      opis: 'Dom św. Piusa X, 1976',
    },
    {
      id: 4,
      preview: PREVIEW.D7_d4p,
      zdjecie: {uri: PATH + "d4.jpg", width: 1324, height: 954},
      opis: 'Dom św. Piusa X, 1977',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D7_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Nowicjat - Dom św. Piusa X, stan obecny',
  },
];
