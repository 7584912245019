import React from 'react'
import { Platform, StyleSheet, ScrollView, View, Text } from 'react-native';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        paddingHorizontal: "10%",
    },
    contentText: {
      textAlign: "justify",
      fontFamily: "CenturyGothic-Regular",
      fontSize: (Platform.OS === "web" ) ? 22 : 10,
      paddingVertical: "1%",
    },
    headerText: {
      alignSelf: "center",
      textAlign: "justify",
      fontFamily: "CenturyGothic-Bold",
      fontSize: (Platform.OS === "web" ) ? 22 : 10,
      paddingVertical: "1%",
    },
    titleText: {
      fontFamily: "CenturyGothic-Bold",
      fontSize: (Platform.OS === "web" ) ? 46 : 22,
      paddingVertical: "1%",
    },
  })

const Polityka = ({navigation, route}) => {
    return (
        <ScrollView>
            <Kratka style={styles.container}>
                <View style={styles.content}>
                  <Text style={[styles.titleText, { alignSelf: "center" }]}>POLITYKA PRYWATNOŚCI</Text>
                  <Text style={styles.headerText}>W TROSCE O TWOJE BEZPIECZEŃSTWO I PRYWATNOŚĆ</Text>
                  <Text style={styles.contentText}>Przygotowana przez nas polityka prywatności zawiera informacje dotyczące przetwarzania Twoich danych osobowych oraz Twoich najważniejszych praw w tym zakresie.</Text>
                  <Text style={styles.headerText}>KTO JEST ADMINISTRATOREM TWOICH DANYCH?</Text>
                  <Text style={styles.contentText}>Administratorem Twoich danych osobowych jest Zgromadzenie Sióstr Urszulanek Serca Jezusa Konającego, ul. św. Urszuli Ledóchowskie 1, 62-045 Pniewy.</Text>
                  <Text style={styles.contentText}>Możesz w każdej chwili skontaktować się z nami za pośrednictwem poczty elektronicznej na adres email: sanktuarium.pniewy@urszulanki.pl, pod nr tel. +48 61 29 38 202 lub pisemnie na powyższy adres.</Text>
                  <Text style={styles.headerText}>TWOJE PRAWA – TO, CO DLA NAS NAJWAŻNIEJSZE</Text>
                  <Text style={styles.contentText}>W każdej chwili masz prawo do żądania dostępu do swoich danych oraz prawo do żądania ich sprostowania, usunięcia, lub ograniczenia przetwarzania.</Text>
                  <Text style={styles.headerText}>MASZ PRAWO DO SPROSTOWANIA</Text>
                  <Text style={styles.contentText}>Masz również prawo do żądania sprostowania dotyczących Ciebie danych osobowych, które są nieprawidłowe. Jeśli za tym przemawia cel przetwarzania Twoich danych masz prawo żądania uzupełnienia niekompletnych danych osobowych, w tym poprzez przedstawienie dodatkowego oświadczenia.</Text>
                  <Text style={styles.headerText}>MOŻESZ COFNĄĆ SWOJĄ ZGODĘ</Text>
                  <Text style={styles.contentText}>Ważne jest, abyś wiedział, że w zakresie w jakim przetwarzamy Twoje dane osobowe na podstawie zgody, przysługuje Tobie prawo do cofnięcia tej zgody w dowolnym momencie, jednak pamiętaj, że pozostaje to bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</Text>
                  <Text style={styles.headerText}>MOŻESZ ZŁOŻYĆ SPRZECIW</Text>
                  <Text style={styles.contentText}>W zakresie w jakim podstawą przetwarzania Twoich danych osobowych jest przesłanka prawnie uzasadnionego naszego interesu (np. realizacja umowy), masz prawo wniesienia sprzeciwu wobec przetwarzania Twoich danych osobowych. Przy czym wskazujemy Tobie, że przetwarzanie Twoich danych, a w szczególności ich przechowywanie może w danym przypadku być niezbędne do ustalenia, dochodzenia lub obrony roszczeń – również Twoich roszczeń.</Text>
                  <Text style={styles.contentText}>Masz prawo do wniesienia sprzeciwu w dowolnym momencie wobec przetwarzania Twoich danych na potrzeby promowania przez nas produktów i usług.</Text>
                  <Text style={styles.headerText}>MASZ PRAWO DO PRZENIESIENIA TWOICH DANYCH</Text>
                  <Text style={styles.contentText}>W przypadku gdy przetwarzanie odbywa się na podstawie Twojej zgody i przetwarzanie odbywa się w sposób zautomatyzowany masz prawo do przenoszenia swoich danych osobowych, tj. do otrzymania od nas swoich danych osobowych w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu maszynowego. Twoje dane mogą zostać przesłane innemu administratorowi.</Text>
                  <Text style={styles.headerText}>MASZ PRAWO DO SKARGI</Text>
                  <Text style={styles.contentText}>Pamiętaj, że zawsze przysługuje Tobie prawo do wniesienia skargi do organu nadzorczego: Prezesa Urzędu Ochrony Danych Osobowych.</Text>
                  <Text style={styles.headerText}>DOBROWOLNOŚĆ TWOJEJ ZGODY</Text>
                  <Text style={styles.contentText}>Podanie przez Ciebie danych osobowych jest dobrowolne, ale niezbędne do świadczenia przez nas usług dla Ciebie.</Text>
                  <Text style={styles.headerText}>Dlaczego przetwarzamy Twoje dane? Jaki jest CEL?</Text>
                  <Text style={styles.contentText}>Celem przetwarzania Twoich danych osobowych jest świadczenie przez nas usług.</Text>
                  <Text style={styles.contentText}>Twoje dane osobowe są przetwarzane również w takich celach, jak:</Text>
                  <Text style={styles.contentText}>– statystycznych, archiwalnych, rachunkowych, wewnętrznej sprawozdawczości, w tym analizy badawczej mającej na celu rozwój i ulepszenie naszych produktów i/lub usług, przeprowadzania audytów wewnętrznych i inspekcji, prowadzenia finansów i księgowości (art. 6 ust. 1 lit. c, f RODO),</Text>
                  <Text style={styles.headerText}>NA JAKIEJ PODSTAWIE SĄ PRZETWARZANE DANE?</Text>
                  <Text style={styles.contentText}>Podstawą prawną przetwarzania Twoich danych osobowych jest:</Text>
                  <Text style={styles.contentText}>– niezbędność danych do wykonywania usług,</Text>
                  <Text style={styles.contentText}>– wypełnianie obowiązków prawnych ciążących na nas, a wynikających m.in. z przepisów ustawy o świadczeniu usług drogą elektroniczną, Kodeksu cywilnego,</Text>
                  <Text style={styles.contentText}>– prawnie uzasadniony interes administratora polegający na promowaniu produktów i usług przez nas oraz ustalenie, dochodzenie lub obrona roszczeń.</Text>
                  <Text style={styles.headerText}>PRZECHOWYWANIE DANYCH</Text>
                  <Text style={styles.contentText}>Chcemy, abyś wiedział, że jako administrator będziemy przechowywać Twoje dane osobowe przez jedynie okres niezbędny do realizacji celów, do których Twoje dane osobowe zostały zebrane lub do których są przetwarzane.</Text>
                  <Text style={styles.contentText}>Przede wszystkim będziemy przechowywać dane osobowe przez okres wykonywania usługi, a następnie do momentu wymaganego przez przepisy prawa.</Text>
                  <Text style={styles.contentText}>Pamiętaj także, że przetwarzanie danych osobowych będzie trwało do czasu wycofania zgody w zakresie, w jakim przetwarzanie danych osobowych odbywało się na podstawie zgody.</Text>
                  <Text style={styles.headerText}>PRZEKAZYWANIE DANYCH – KATEGORIE ODBIORCÓW</Text>
                  <Text style={styles.contentText}>Wykonując naszą działalność będziemy przekazywać Twoje dane osobowe zaufanym podmiotom, z którymi współpracujemy (w szczególności pracownikom, podmiotom świadczącym usługi informatyczne i teleinformatyczne, księgowo-rachunkowe, prawne, doradcze). W przypadkach przewidzianych w prawie możemy przekazywać Twoje dane osobowe sądom, instytucjom i organom ochrony prawnej lub nadzoru.</Text>
                  <Text style={styles.contentText}>Na podstawie przepisów prawa i odpowiednich umów możemy także powierzać przetwarzanie danych osobowych innym podmiotom.</Text>
                  <Text style={styles.contentText}>Nie będziemy przekazywać Twoich danych osobowych do odbiorców znajdujących się w państwie trzecim, państwach poza Europejskiego Obszaru Gospodarczego, w tym organizacji międzynarodowych.</Text>
                  <Text style={styles.headerText}>AUTOMATYCZNE PRZETWARZANIE – PROFILOWANIE</Text>
                  <Text style={styles.contentText}>Chcemy, abyś wiedział, że nie podejmujemy decyzji, która opiera się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu, i wywołuje wobec Ciebie skutki prawne lub w podobny sposób istotnie na Ciebie wpływa.</Text>
                </View>
                <Stopka />
            </Kratka>
        </ScrollView>
    );
}

export default Polityka;