import React from 'react';
import { Platform } from 'react-native';

export const shadow = ({ color, opacity, radius, offsetWidth, offsetHeight, elevation }) => {
    return Platform.select({
      android: {
        elevation
      },
      ios: {
        shadowColor: color,
        shadowOpacity: opacity,
        shadowRadius: radius,
        shadowOffset: {
          width: offsetWidth,
          height: offsetHeight,
        }
      },
      web: {
        shadowColor: color,
        shadowOpacity: opacity,
        shadowRadius: radius,
        shadowOffset: {
          width: offsetWidth,
          height: offsetHeight,
        }
      }
    });
};