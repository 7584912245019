// Szkoła św. Stanisława

import React, { useState, useRef, useEffect } from 'react';
import { Platform, View, ScrollView, Text, Image, Animated as RNAnimated, TouchableWithoutFeedback, PixelRatio, useWindowDimensions } from 'react-native';
import { Audio, Video } from 'expo-av';
import { useFocusEffect } from '@react-navigation/native';
import { SharedElement } from 'react-navigation-shared-element';
import { dawno, aktualnie } from './zdjeciaD2';
import { Img } from '../components/Img';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import FullWidthImage from 'react-native-fullwidth-image';
import { styles, PREFIX } from './stylesDomki';
import { O } from "../components/Obrazy";
import * as Obrazy from "../components/Obrazy";

export const D2 = (props) => {
    const navigation = props.navigation;
    const domek = props.route.params.domek;
    const opacity = React.useMemo(() => new RNAnimated.Value(0), []);
    const [ videoHeight, setVideoHeight ] = useState(0);
    const windim = useWindowDimensions();

    function fontSize(size) {
      return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
    }

    const videoRef = useRef(null);

    useEffect(() => {
      //console.log("mount");
      (async ()=>{
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: false,
          staysActiveInBackground: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true,
          shouldDuckAndroid: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          playThroughEarpieceAndroid: false
        });  
      })();
    }, []);
    
    useEffect(() => async () => {
      if ( videoRef.current != null )
        await videoRef.current.stopAsync();
      /*
      if ( videoRef.current != null )
        await videoRef.current.unloadAsync();
      */
    }, []);
  
    useFocusEffect(
      React.useCallback(() => {
        // Do something when the screen is focused
        return async () => {
          // Do something when the screen is unfocused
          // Useful for cleanup functions
          if ( videoRef.current != null )
            await videoRef.current.stopAsync();
          /*
          if ( videoRef.current != null )
            await videoRef.current.unloadAsync();
          */
        };
      }, [])
    );
  
    return (
      <Kratka style={styles.container}>
      <ScrollView>
        { Platform.OS === 'web' &&
        <FullWidthImage source={O.D2.D2_Domek} />
        }
        { Platform.OS !== 'web' &&
        <Image source={O.D2.D2_Domek} style={styles.domek} />
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Image source={Obrazy.Pionek} resizeMode="contain" style={styles.pionek} />
          </View>
          <View style={[styles.content2, styles.contentOpis]}>
            <Text style={[styles.opis, { fontSize: fontSize(24) }]}>
              „Matuchnie Pniewy bardzo się podobały zewnętrznie: położenie, jezioro, otoczenie, bliskość Lwówka. Po paru godzinach rozpogodziło się i Matuchna mogła wszystko dokładnie obejrzeć. Dużej willi nadała imię św. Stanisława i przeznaczyła ją na szkołę i pensjonat (…)” (ze wspomnień siostry Stanisławy Prądzyńskiej)
            </Text>
            <Text style={[styles.opis, { fontSize: fontSize(24), marginTop: "2%" }]}>
              Wychowanie dzieci i młodzieży to główne zadanie sióstr urszulanek, dlatego już 1 września 1920 roku rozpoczęły się lekcje w pniewskiej szkole. Z wieloma perypetiami i zmianami funkcjonuje do dziś. Co jakiś czas odbywają się spotkania byłych uczennic (Olafitek), które z radością wracają do „swoich” szkolnych ławek.
            </Text>
          </View>
        </View>
        { Platform.OS === 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <FullWidthImage source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View onContextMenu={(e)=>{e.preventDefault();}} style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/D2.mp4", width: 1920, height: 1080 }}
              onLoad={()=>{
                document.querySelector("video").controlsList = "nodownload";
                RNAnimated.timing(opacity, {
                  toValue: 1,
                  ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }]}
            />
          </RNAnimated.View>
        </View>
        }
        { Platform.OS !== 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <Image source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/D2m.mp4", width: 1280, height: 720 }}
              onLoad={()=>{RNAnimated.timing(opacity, {
                toValue: 1,
                ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }]}
            />
          </RNAnimated.View>
        </View>
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Dawno, dawno temu…</Text>
          </View>
          <View style={[styles.content2, styles.galeria]}>
                {dawno.map((zdjecie, i) => 
                <TouchableWithoutFeedback key={"zd"+i} onPress={() => navigation.navigate("Zdjecie", { zdjecie, indeks: i, zdjecia: dawno })}>
                  <SharedElement id={zdjecie.id}>
                    <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                  </SharedElement>
                </TouchableWithoutFeedback>
                )}
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Aktualnie…</Text>
          </View>
          <View style={[styles.content2, styles.galeria]}>
                {aktualnie.map((zdjecie, i) => 
                <TouchableWithoutFeedback key={"za"+i} onPress={() => navigation.navigate("Zdjecie", { zdjecie, indeks: i, zdjecia: aktualnie })}>
                  <SharedElement id={zdjecie.id}>
                    <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                  </SharedElement>
                </TouchableWithoutFeedback>
                )}
          </View>
        </View>
        <Stopka />
      </ScrollView>
      </Kratka>
    );
}
