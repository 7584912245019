import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D16/";
const PREVIEW = O.D16;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D16_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1623, height: 1071},
      opis: 'Budowa, r. 1931',
    },
    {
      id: 2,
      preview: PREVIEW.D16_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 787, height: 519},
      opis: 'Rok 1991',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D16_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Jadwigi, stan obecny',
  },
];
