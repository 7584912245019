import React, { useState, useEffect } from 'react';
import { Platform, StyleSheet, View, Image, Easing } from 'react-native';
import { Animated as RNAnimated } from 'react-native';

export function Img({source, preview, style, onLoad, hoverScale, fadeInPreview, ...rest}) {
  const [ anim, setAnim ] = useState(null);
  const [ isPreviewLoaded, setPreviewLoaded ] = useState(false);
  const [ isImageLoaded, setImageLoaded ] = useState(false);
  const [ isAnimFinished, setAnimFinished ] = useState(false);
  const [ isPreviewAnimFinished, setPreviewAnimFinished ] = useState(fadeInPreview ? false : true);
  const [ opacity ] = useState(new RNAnimated.Value(fadeInPreview ? 0 : 1));

  if ( fadeInPreview )
  {
    useEffect(() => {
      isPreviewLoaded && RNAnimated.timing(opacity, { 
        toValue: 1,
        duration: 500,
        //easing: Easing.inOut(Easing.ease),
        ...Platform.select({ "native": { useNativeDriver: true } }),
      }).start(({finished}) => {
        setPreviewAnimFinished(finished);
      })
    },[ isPreviewLoaded ]);
  }

  useEffect(() => {
    isImageLoaded && isPreviewAnimFinished && RNAnimated.timing(opacity, { 
      toValue: 0,
      duration: 3000,
      //easing: Easing.inOut(Easing.ease),
      ...Platform.select({ "native": { useNativeDriver: true } }),
    }).start(({finished}) => {
      setAnimFinished(finished);
      ( !! onLoad ) && onLoad();
    })
  },[ isImageLoaded, isPreviewAnimFinished ]);

  if ( Platform.OS === "web" && hoverScale )
    return (
      <View
        style={[ style, { overflow: "hidden" } ]}
        onMouseOver={()=>{ setAnim({ transform: [{ scale: 1.2 }]}) }}
        onMouseOut={()=>{ setAnim(null) }}>
        { isPreviewAnimFinished && <RNAnimated.Image
          source={source}
          style={[ style, anim, { opacity: opacity.interpolate({ inputRange: [0.95, 1], outputRange: [1, 0], extrapolate: "clamp"}), margin: 0 } ]}
          onLoad={(e)=>{ setImageLoaded(true) }}
          {...rest}
          />}
        { ! isAnimFinished && <RNAnimated.Image
          blurRadius={ ( Platform.OS === 'android' ) ? 0.5 : 5 }
          source={preview}
          style={[StyleSheet.absoluteFill, style, { opacity, margin: 0 }]}
          onLoad={(e)=>{ setPreviewLoaded(true) }}
          />}
      </View>
    );
  else
    return (
      <View
        style={style}>
        { isPreviewAnimFinished && <RNAnimated.Image
          source={source}
          style={[ style, { opacity: opacity.interpolate({ inputRange: [0.95, 1], outputRange: [1, 0], extrapolate: "clamp"}), margin: 0 } ]}
          onLoad={(e)=>{ setImageLoaded(true) }}
          {...rest}
          />}
        { ! isAnimFinished && <RNAnimated.Image
          blurRadius={ ( Platform.OS === 'android' ) ? 0.5 : 5 }
          source={preview}
          style={[StyleSheet.absoluteFill, style, { opacity, margin: 0 }]}
          onLoad={(e)=>{ setPreviewLoaded(true) }}
          />}
      </View>
    );
};
  