import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D5/";
const PREVIEW = O.D5;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D5_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 629, height: 609},
      opis: 'Dom św. Franciszka, nabyty w VI 1925 r.',
    },
    {
      id: 2,
      preview: PREVIEW.D5_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 1920, height: 1357},
      opis: 'Dom św. Franciszka, rok 1926',
    },
    {
      id: 3,
      preview: PREVIEW.D5_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 343, height: 500},
      opis: 'Św. Urszula z osiołkiem',
    },
    {
      id: 4,
      preview: PREVIEW.D5_d4p,
      zdjecie: {uri: PATH + "d4.jpg", width: 627, height: 1221},
      opis: 'Na podwórzu pniewskim, r. 1936',
    },
    {
      id: 5,
      preview: PREVIEW.D5_d5p,
      zdjecie: {uri: PATH + "d5.jpg", width: 315, height: 489},
      opis: 'Św. Urszula z króliczkami',
    },
    {
      id: 6,
      preview: PREVIEW.D5_d6p,
      zdjecie: {uri: PATH + "d6.jpg", width: 801, height: 529},
      opis: 'Dawne przedszkole',
    },
    {
      id: 7,
      preview: PREVIEW.D5_d7p,
      zdjecie: {uri: PATH + "d7.jpg", width: 819, height: 532},
      opis: 'Dom św. Franciszka, rok 1975',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D5_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Franciszka, stan obecny',
  },
  {
    id: 102,
    preview: PREVIEW.D5_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Franciszka, stan obecny',
  },
];
