import React from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { Svg, Pattern, Defs, Rect, Path } from 'react-native-svg';

const styles = StyleSheet.create({
    svg: {
        ...StyleSheet.absoluteFillObject,
        width: "100%",
        height: "100%",
    }
});

export const Kratka = (props) => {
    const windim = useWindowDimensions();
    const id = "kratka"+(new Date()).valueOf();
    return (
        <View {...props}>
            <Svg style={styles.svg} width={windim.width} height={windim.height}>
                <Defs>
                    <Pattern
                        id={id}
                        patternUnits="userSpaceOnUse"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30">
                        <Path d="M 0 0 L 0 30 L 30 30" fill="none" stroke="lightgrey" strokeWidth="1" />
                    </Pattern>
                </Defs>
                <Rect fill={"url(#"+id+")"} width="100%" height="100%" />
            </Svg>
            {props.children}
            <StatusBar style="light" />
        </View>
    );
};
