import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D0/";
const PREVIEW = O.D0;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D0_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1132, height: 768},
      opis: 'Willa Rabigera, zakupiona 11 II 1920',
    },
    {
      id: 2,
      preview: PREVIEW.D0_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 2080, height: 1384},
      opis: 'Rok 1969',
    },
    {
      id: 3,
      preview: PREVIEW.D0_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 652, height: 902},
      opis: 'Przy biurku w Pniewach, 28 II 1926',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D0_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1772, height: 1016},
    opis: 'Stan aktualny',
  },
  {
    id: 102,
    preview: PREVIEW.D0_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 2048, height: 1536},
    opis: 'Stan aktualny',
  },
  {
    id: 103,
    preview: PREVIEW.D0_a3p,
    zdjecie: {uri: PATH + "a3.jpg", width: 1824, height: 1368},
    opis: 'Stan aktualny',
  },
  {
    id: 104,
    preview: PREVIEW.D0_a4p,
    zdjecie: {uri: PATH + "a4.jpg", width: 1824, height: 1368},
    opis: 'Stan aktualny',
  },
];
