import React from 'react';
import { Platform, StyleSheet, View, Image, Text, TouchableOpacity } from 'react-native';

export const headerStyles = StyleSheet.create({
    header: {
        backgroundColor: '#526060',
        //height: 80,
    },
    headerContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    },
    headerTitle: {
        flex: 1,
        textAlign: "right",
        color: 'white',
        fontFamily: 'CenturyGothic-Regular',
        fontSize: ( Platform.OS === 'web' ) ? 18 : 12,
        //letterSpacing: ( Platform.OS === 'android' || Platform.OS === 'ios' ) ? -1 : 0,
    },
    headerRight: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        width: 50,
        height: 50,
        borderRadius: 25,
    },
    headerImage: {
        ...Platform.select({
            "web": {
                width: 79,
                height: 40,
                marginRight: 10,
                resizeMode: "contain",
            },
            "android": {
                width: 79,
                height: 40,
                aspectRatio: 79 / 40,
                marginRight: 10,
                resizeMode: "contain",
            },
            "ios": {
                width: undefined,
                height: "80%",
                aspectRatio: 79 / 40,
                marginRight: 10,
                resizeMode: "contain",
            },
        })
    },
});

export function LogoTitle(props) {
    return (
        <><TouchableOpacity onPress={() => props.navigation.openDrawer()}>
        <Image
            style={headerStyles.headerImage}
            source={require('../../assets/logo/header.png')}
        />
        </TouchableOpacity>
        <Text numberOfLines={1} style={props.style}>{props.children}</Text></>
    );
}
