import React, { useRef, useEffect } from 'react';
import { Platform, StyleSheet, ScrollView, View, Text, Image, Animated as RNAnimated, useWindowDimensions } from 'react-native';
import { Audio, Video } from 'expo-av';
import { useHeaderHeight } from '@react-navigation/stack';
import { useFocusEffect } from '@react-navigation/native';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import { PREFIX } from "./stylesDomki";

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
    },
    content: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    }
})

const Bajka = ({navigation, route}) => {
    const windim = useWindowDimensions();
    const headerHeight = useHeaderHeight();
    const safeWidth = windim.width;
    const safeHeight = windim.height - headerHeight;
    const opacity = new RNAnimated.Value(0);
    const videoRef = useRef(null);

    useEffect(() => {
      //console.log("mount");
      (async ()=>{
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: false,
          staysActiveInBackground: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true,
          shouldDuckAndroid: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          playThroughEarpieceAndroid: false
        });  
      })();
    }, []);
    
    useEffect(() => async () => {
      if ( videoRef.current != null )
        await videoRef.current.stopAsync();
      /*
      if ( videoRef.current != null )
        await videoRef.current.unloadAsync();
      */
    }, []);

    useFocusEffect(
      React.useCallback(() => {
        // Do something when the screen is focused
        return async () => {
          // Do something when the screen is unfocused
          // Useful for cleanup functions
          if ( videoRef.current != null )
            await videoRef.current.stopAsync();
          /*
          if ( videoRef.current != null )
            await videoRef.current.unloadAsync();
          */
        };
      }, [])
    );
  
    return (
      <Kratka style={styles.container}>
          <ScrollView>
                <View style={styles.content}>
            { Platform.OS === 'web' && 
      <RNAnimated.View onContextMenu={(e)=>{e.preventDefault();}} style={{ opacity: opacity, alignSelf: 'center', height: safeHeight * 0.9, width: safeHeight * 0.9 * 1920 / 1080  }}>
      <Video
        ref={videoRef}
        source={{ uri: PREFIX + "piecpiaty.pl/assets/Ulam.mp4", width: 1920, height: 1080 }}
        onLoad={()=>{
          document.querySelector("video").controlsList = "nodownload";
          RNAnimated.timing(opacity, {
          toValue: 1,
          ...Platform.select({ "native": { useNativeDriver: true } }),
        }).start((finished)=>{
          if ( finished )
          {
//            try { videoRef.current.presentFullscreenPlayer(); } catch (e) {};
          }
        });}}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        resizeMode="contain"
        shouldPlay
        useNativeControls
        style={{ height: safeHeight * 0.9, width: safeHeight * 0.9 * 1920 / 1080 }}
      />
      </RNAnimated.View>}
      { Platform.OS !== 'web' && 
      <RNAnimated.View style={{ opacity: opacity, alignSelf: 'center', height: safeHeight * 0.9, width: safeHeight * 0.9 * 1280 / 720 }}>
      <Video
        ref={videoRef}
        source={{ uri: PREFIX + "piecpiaty.pl/assets/Ulam.mp4", width: 1280, height: 720 }}
        onLoad={()=>{
          RNAnimated.timing(opacity, {
          toValue: 1,
          ...Platform.select({ "native": { useNativeDriver: true } }),
        }).start((finished)=>{
          if ( finished )
          {
//              try { videoRef.current.presentFullscreenPlayer(); } catch (e) {};
          }
        });}}
        rate={1.0}
        volume={1.0}
        isMuted={false}
        resizeMode="contain"
        shouldPlay
        useNativeControls
        style={{ height: safeHeight * 0.9, width: safeHeight * 0.9 * 1280 / 720 }}
      />
      </RNAnimated.View>}
      </View>
                <Stopka />
            </ScrollView>
        </Kratka>
    );
}

export default Bajka;