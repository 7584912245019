import React, { useState, useEffect } from 'react'
import { Platform, StyleSheet, View, Text, Image, Dimensions, Animated as RNAnimated } from 'react-native';
import { SharedElement } from 'react-navigation-shared-element';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import Swiper from 'react-native-web-swiper';

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        flex: 1,
    },
    zdjecie: {
        width: "100%",
        height: "100%",
        resizeMode: 'contain',
        //backgroundColor: "rgba(0,0,0,0.3)",
    },
})

const Zdjecie = ({navigation, route}) => {
    const { indeks, zdjecia } = route.params;
    //console.log("z: ", zdjecie.id);
/*
    const [ opacity ] = useState(new RNAnimated.Value(0));

    useEffect(()=>{
        RNAnimated.timing(opacity, {
            toValue: 1,
            duration: 500,
            ...Platform.select({ "native": { useNativeDriver: true } }),
        }).start(({finished}) => {
        });
    });
*/
    return (
            <View style={styles.container}>
{/*            <RNAnimated.View style={[styles.container, { opacity }]}>*/}
                <Swiper
                    from={indeks}
                    minDistanceForAction={0.1}
                    controlsProps={{
                        dotsTouchable: true,
                        prevPos: 'left',
                        nextPos: 'right',
                        nextTitle: '>',
                        nextTitleStyle: { fontFamily: "CenturyGothic-Regular", color: 'white', fontSize: 46 },
                        prevTitle: '<',
                        prevTitleStyle: { fontFamily: "CenturyGothic-Regular", color: 'white', fontSize: 46 },
                    }}
                >
                {zdjecia.map((zdjecie, i) =>
                    <View key={"v"+i} style={{ flex: 1, alignItems:"center", justifyContent:"center" }}>
                        {(indeks == i) && <SharedElement id={zdjecie.id} style={styles.zdjecie}>
                            <Image source={zdjecie.zdjecie} style={styles.zdjecie} />
                        </SharedElement>}
                        {(indeks != i) && <Image source={zdjecie.zdjecie} style={styles.zdjecie} />}
                        {!!zdjecie.opis && <View style={{ borderRadius: 15, position: "absolute", bottom: "10%", backgroundColor: "rgba(20,20,20,0.6)", padding: "2%", alignItems: "center", justifyContent: "center" }}><Text numberOfLines={1} style={{ fontFamily: "CenturyGothic-Regular", color: 'white', fontSize: 22 }}>{zdjecie.opis}</Text></View>}
                    </View>
                )}
                </Swiper>
                {/*
                <SharedElement id={zdjecie.id} style={styles.zdjecie}>
                     <Image source={zdjecie.zdjecie} style={styles.zdjecie} />
                </SharedElement>
                <Stopka />
                */}
{/*            </RNAnimated.View>*/}
        </View>
    );
}

export default Zdjecie;