import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D11/";
const PREVIEW = O.D11;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D11_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1072, height: 793},
      opis: 'Z gośćmi przy żniwach, r. 1926',
    },
    {
      id: 2,
      preview: PREVIEW.D11_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 1071, height: 806},
      opis: 'Przy żniwach',
    },
    {
      id: 3,
      preview: PREVIEW.D11_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 997, height: 716},
      opis: 'Budynek św. Klary, r. 1979',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D11_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Budynek św. Klary, stan obecny',
  },
  {
    id: 102,
    preview: PREVIEW.D11_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 817, height: 529},
    opis: 'Budynek św. Klary zimą',
  },
];
