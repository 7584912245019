import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D12/";
const PREVIEW = O.D12;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D12_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1920, height: 1328},
      opis: 'Centralna kotłownia i cieplarnia, r. 1982',
    },
    {
      id: 2,
      preview: PREVIEW.D12_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 808, height: 518},
      opis: 'Poświęcenie domu Aniołów Stróżów, r. 1983',
    },
    {
      id: 3,
      preview: PREVIEW.D12_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 1919, height: 1282},
      opis: 'Budynek internatu, r. 1991',
    },
    {
      id: 4,
      preview: PREVIEW.D12_d4p,
      zdjecie: {uri: PATH + "d4.jpg", width: 1920, height: 1518},
      opis: 'Kapitel pochodzący z dawnej kaplicy',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D12_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Dom Aniołów Stróżów, stan obecny',
  },
  {
    id: 102,
    preview: PREVIEW.D12_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1440},
    opis: 'Dom Aniołów Stróżów, stan obecny',
  },
];
