import React, { useState } from 'react'
import { Platform, PixelRatio, StyleSheet, View, Text, Image, ScrollView, TouchableOpacity, Linking, useWindowDimensions } from 'react-native';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import * as Obrazy from "../components/Obrazy";
import { shadow } from '../components/Narzedzia';

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  baner: {
    width: '100%',
    height: undefined,
    aspectRatio: 1920 / 356,
  },
  pionek: {
    width: 200 / PixelRatio.get(),
    height: 200 / PixelRatio.get() * 560 / 258,
    aspectRatio: 560 / 258,
    position: "absolute",
    bottom: -100 / PixelRatio.get(), 
    alignSelf: "center",
  },
  contentContainer: {
    flexDirection: "row",
  },
  content1: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content2: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentOpis: {
    padding: "8%",
    backgroundColor: 'white',
  },
  tekst: {
    textAlign: "justify",
    fontFamily: "CenturyGothic-Regular",
    fontSize: (Platform.OS === "web" ) ? 24 : 12,
  },
  pikto: {
    padding: 20,
    top: 0,
    left: 0,
    alignItems: "center",
    ...Platform.select({ "web": {
      transition: "all 0.5s ease" //, transform 1.2s ease-out",
    }})
  },
});

const Quizy = ({navigation, route}) => {
  const windim = useWindowDimensions();

  function fontSize(size) {
    return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
  }

  async function openURL(url, target) {
      if ( ! url || url === "" )
        return;
      if ( Platform.OS == 'web' )
        window.open(url, target);
      else
      {
        const supported = await Linking.canOpenURL(url);
        if ( supported )
          await Linking.openURL(url);
      }
    }

    const PIKTO_SIZE = Math.floor(windim.width / 5);
    //const PIKTO_SIZE_POL = PIKTO_SIZE / 2;
    const SHADOW = {left: -10, top: -10, ...shadow({
      elevation: 20,
      color: 'black',
      opacity: 0.5,
      radius: 20,
      offsetWidth: 5,
      offsetHeight: 5,
    })};

    const [ s1, setS1 ] = useState(null);
    const [ s2, setS2 ] = useState(null);
    const [ s3, setS3 ] = useState(null);
    const [ s4, setS4 ] = useState(null);
    const [ s5, setS5 ] = useState(null);
    const [ s6, setS6 ] = useState(null);
    const [ s7, setS7 ] = useState(null);
    const [ s8, setS8 ] = useState(null);
    const [ s9, setS9 ] = useState(null);
    const [ s10, setS10 ] = useState(null);
    const [ s11, setS11 ] = useState(null);
    const [ s12, setS12 ] = useState(null);

    return (
      <Kratka style={styles.container}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <View style={{ flexDirection: "row", justifyContent: "space-evenly", margin: "4%" }}>
          <TouchableOpacity onPress={() => navigation.navigate("Quiz")}>
            <View style={[styles.pikto, s10]} onMouseOver={()=>{
                setS10(SHADOW);
              }} onMouseOut={()=>{
                setS10(null);
              }}>
              <Image source={Obrazy.GryInne} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Ile wiesz o św. Urszuli?</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate("Quiz2")}>
            <View style={[styles.pikto, s11]} onMouseOver={()=>{
                setS11(SHADOW);
              }} onMouseOut={()=>{
                setS11(null);
              }}>
              <Image source={Obrazy.GryInne} resizeMode="contain" style={{ flex: 1, width: PIKTO_SIZE, height: PIKTO_SIZE }} />
              <Text style={[styles.tekst, { fontSize: fontSize(24), marginTop: "10%" }]}>Sprawdź podobieństwo do św. Urszuli</Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ flexGrow: 1 }} />
        <Stopka />
      </ScrollView>
      </Kratka>
    );
}

export default Quizy;