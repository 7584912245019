//import { Asset } from "expo";

// Sanktuarium
const Legenda = require("../../assets/legenda.png");
const Mapa = require("../../assets/mapa.png");
//const MapaPreview = require("../../assets/mapa-preview.png");
const Napis1 = require("../../assets/napis1.png");
const Napis2 = require("../../assets/napis2.png");
const Pionek = require("../../assets/pionek.png");
const PionekMapa = require("../../assets/pionek_mapa.png");

// Galeria Maryjo Świeć
const D0_Domek = require('../../assets/D0/domek.jpg');
const D0_d1p = require("../../assets/D0/d1p.jpg");
const D0_d2p = require("../../assets/D0/d2p.jpg");
const D0_d3p = require("../../assets/D0/d3p.jpg");
const D0_a1p = require("../../assets/D0/a1p.jpg");
const D0_a2p = require("../../assets/D0/a2p.jpg");
const D0_a3p = require("../../assets/D0/a3p.jpg");
const D0_a4p = require("../../assets/D0/a4p.jpg");

// Galeria Kaplica
const D1_Domek = require('../../assets/D1/domek.jpg');
const D1_d1p = require("../../assets/D1/d1p.jpg");
const D1_d2p = require("../../assets/D1/d2p.jpg");
const D1_d3p = require("../../assets/D1/d3p.jpg");
const D1_d4p = require("../../assets/D1/d4p.jpg");
const D1_d5p = require("../../assets/D1/d5p.jpg");
const D1_a1p = require("../../assets/D1/a1p.jpg");
const D1_a2p = require("../../assets/D1/a2p.jpg");
const D1_a3p = require("../../assets/D1/a3p.jpg");
const D1_a4p = require("../../assets/D1/a4p.jpg");
const D1_a5p = require("../../assets/D1/a5p.jpg");
const D1_a6p = require("../../assets/D1/a6p.jpg");

// Galeria Szkoła
const D2_Domek = require('../../assets/D2/domek.jpg');
const D2_d1p = require("../../assets/D2/d1p.jpg");
const D2_d2p = require("../../assets/D2/d2p.jpg");
const D2_a1p = require("../../assets/D2/a1p.jpg");
const D2_a2p = require("../../assets/D2/a2p.jpg");

// Galeria Szkoła
const D3_Domek = require('../../assets/D3/domek.jpg');
const D3_d1p = require("../../assets/D3/d1p.jpg");
const D3_a1p = require("../../assets/D3/a1p.jpg");
const D3_g1p = require("../../assets/D3/g1p.jpg");
const D3_g2p = require("../../assets/D3/g2p.jpg");
const D3_g3p = require("../../assets/D3/g3p.jpg");
const D3_g4p = require("../../assets/D3/g4p.jpg");
const D3_g5p = require("../../assets/D3/g5p.jpg");
const D3_g6p = require("../../assets/D3/g6p.jpg");
const D3_g7p = require("../../assets/D3/g7p.jpg");
const D3_g8p = require("../../assets/D3/g8p.jpg");
const D3_g9p = require("../../assets/D3/g9p.jpg");
const D3_g10p = require("../../assets/D3/g10p.jpg");
const D3_g11p = require("../../assets/D3/g11p.jpg");

// Galeria Przedszkole św. Rodziny
const D4_Domek = require('../../assets/D4/domek.jpg');
const D4_d1p = require("../../assets/D4/d1p.jpg");
const D4_d2p = require("../../assets/D4/d2p.jpg");
const D4_d3p = require("../../assets/D4/d3p.jpg");
const D4_d4p = require("../../assets/D4/d4p.jpg");
const D4_a1p = require("../../assets/D4/a1p.jpg");

// Galeria Dom św. Franciszka
const D5_Domek = require('../../assets/D5/domek.jpg');
const D5_d1p = require("../../assets/D5/d1p.jpg");
const D5_d2p = require("../../assets/D5/d2p.jpg");
const D5_d3p = require("../../assets/D5/d3p.jpg");
const D5_d4p = require("../../assets/D5/d4p.jpg");
const D5_d5p = require("../../assets/D5/d5p.jpg");
const D5_d6p = require("../../assets/D5/d6p.jpg");
const D5_d7p = require("../../assets/D5/d7p.jpg");
const D5_a1p = require("../../assets/D5/a1p.jpg");
const D5_a2p = require("../../assets/D5/a2p.jpg");

// Galeria Dom św. Anny
const D6_Domek = require('../../assets/D6/domek.jpg');
const D6_d1p = require("../../assets/D6/d1p.jpg");
const D6_d2p = require("../../assets/D6/d2p.jpg");
const D6_a1p = require("../../assets/D6/a1p.jpg");

// Nowicjat Dom św. Piusa X
const D7_Domek = require('../../assets/D7/domek.jpg');
const D7_d1p = require("../../assets/D7/d1p.jpg");
const D7_d2p = require("../../assets/D7/d2p.jpg");
const D7_d3p = require("../../assets/D7/d3p.jpg");
const D7_d4p = require("../../assets/D7/d4p.jpg");
const D7_a1p = require("../../assets/D7/a1p.jpg");

// Dom św. Kalasantego
const D8_Domek = require('../../assets/D8/domek.jpg');
const D8_d1p = require("../../assets/D8/d1p.jpg");
const D8_a1p = require("../../assets/D8/a1p.jpg");
const D8_a2p = require("../../assets/D8/a2p.jpg");

// Galeria św. Aniela
const D9_Domek = require('../../assets/D9/domek.jpg');
const D9_d1p = require("../../assets/D9/d1p.jpg");
const D9_d2p = require("../../assets/D9/d2p.jpg");
const D9_d3p = require("../../assets/D9/d3p.jpg");
const D9_a1p = require("../../assets/D9/a1p.jpg");
const D9_a2p = require("../../assets/D9/a2p.jpg");
const D9_a3p = require("../../assets/D9/a3p.jpg");

// Galeria Dom Matki Założycielki
const D10_Domek = require('../../assets/D10/domek.jpg');
const D10_d1p = require("../../assets/D10/d1p.jpg");
const D10_d2p = require("../../assets/D10/d2p.jpg");
const D10_d3p = require("../../assets/D10/d3p.jpg");
const D10_a1p = require("../../assets/D10/a1p.jpg");
const D10_a2p = require("../../assets/D10/a2p.jpg");
const D10_a3p = require("../../assets/D10/a3p.jpg");

// Galeria Budynek św. Klary
const D11_Domek = require('../../assets/D11/domek.jpg');
const D11_d1p = require("../../assets/D11/d1p.jpg");
const D11_d2p = require("../../assets/D11/d2p.jpg");
const D11_d3p = require("../../assets/D11/d3p.jpg");
const D11_a1p = require("../../assets/D11/a1p.jpg");
const D11_a2p = require("../../assets/D11/a2p.jpg");

// Galeria Dom Aniołów Stróżów
const D12_Domek = require('../../assets/D12/domek.jpg');
const D12_d1p = require("../../assets/D12/d1p.jpg");
const D12_d2p = require("../../assets/D12/d2p.jpg");
const D12_d3p = require("../../assets/D12/d3p.jpg");
const D12_d4p = require("../../assets/D12/d4p.jpg");
const D12_a1p = require("../../assets/D12/a1p.jpg");
const D12_a2p = require("../../assets/D12/a2p.jpg");

// Galeria Dom św. Antoniego
const D13_Domek = require('../../assets/D13/domek.jpg');
const D13_d1p = require("../../assets/D13/d1p.jpg");
const D13_d2p = require("../../assets/D13/d2p.jpg");
const D13_d3p = require("../../assets/D13/d3p.jpg");
const D13_d4p = require("../../assets/D13/d4p.jpg");
const D13_a1p = require("../../assets/D13/a1p.jpg");
const D13_a2p = require("../../assets/D13/a2p.jpg");

// Galeria Budynek św. Jana Pawła II
const D14_Domek = require('../../assets/D14/domek.jpg');
const D14_d1p = require("../../assets/D14/d1p.jpg");
const D14_a1p = require("../../assets/D14/a1p.jpg");
const D14_a2p = require("../../assets/D14/a2p.jpg");
const D14_a3p = require("../../assets/D14/a3p.jpg");
const D14_a4p = require("../../assets/D14/a4p.jpg");

// Galeria Dom św. Józefa
const D15_Domek = require('../../assets/D15/domek.jpg');
const D15_d1p = require("../../assets/D15/d1p.jpg");
const D15_d2p = require("../../assets/D15/d2p.jpg");
const D15_a1p = require("../../assets/D15/a1p.jpg");
const D15_a2p = require("../../assets/D15/a2p.jpg");
const D15_a3p = require("../../assets/D15/a3p.jpg");
const D15_a4p = require("../../assets/D15/a4p.jpg");

// Galeria Budynek św. Jadwigi
const D16_Domek = require('../../assets/D16/domek.jpg');
const D16_d1p = require("../../assets/D16/d1p.jpg");
const D16_d2p = require("../../assets/D16/d2p.jpg");
const D16_a1p = require("../../assets/D16/a1p.jpg");

export const O = {
    Sanktuarium: {
        Legenda,
        Mapa,
        //MapaPreview,
        Napis1,
        Napis2,
        Pionek,
        PionekMapa,
    },
    SanktuariumArray: [
        Legenda,
        Mapa,
        //MapaPreview,
        Napis1,
        Napis2,
        Pionek,
        PionekMapa,
    ],
    D0: {
        D0_Domek,
        D0_d1p, D0_d2p, D0_d3p,
        D0_a1p, D0_a2p, D0_a3p, D0_a4p,
    },
    D1: {
        D1_Domek,
        D1_d1p, D1_d2p, D1_d3p, D1_d4p, D1_d5p,
        D1_a1p, D1_a2p, D1_a3p, D1_a4p, D1_a5p, D1_a6p,
    },
    D2: {
        D2_Domek,
        D2_d1p, D2_d2p,
        D2_a1p, D2_a2p,
    },
    D3: {
        D3_Domek,
        D3_d1p,
        D3_a1p,
        D3_g1p, D3_g2p, D3_g3p, D3_g4p, D3_g5p, D3_g6p, D3_g7p, D3_g8p, D3_g9p, D3_g10p, D3_g11p,
    },
    D4: {
        D4_Domek,
        D4_d1p, D4_d2p, D4_d3p, D4_d4p,
        D4_a1p,
    },
    D5: {
        D5_Domek,
        D5_d1p, D5_d2p, D5_d3p, D5_d4p, D5_d5p, D5_d6p, D5_d7p,
        D5_a1p, D5_a2p,
    },
    D6: {
        D6_Domek,
        D6_d1p, D6_d2p,
        D6_a1p,
    },
    D7: {
        D7_Domek,
        D7_d1p, D7_d2p, D7_d3p, D7_d4p,
        D7_a1p,
    },
    D8: {
        D8_Domek,
        D8_d1p,
        D8_a1p, D8_a2p,
    },
    D9: {
        D9_Domek,
        D9_d1p, D9_d2p, D9_d3p,
        D9_a1p, D9_a2p, D9_a3p,    
    },
    D10: {
        D10_Domek,
        D10_d1p, D10_d2p, D10_d3p,
        D10_a1p, D10_a2p, D10_a3p,
    },
    D11: {
        D11_Domek,
        D11_d1p, D11_d2p, D11_d3p,
        D11_a1p, D11_a2p,
    },
    D12: {
        D12_Domek,
        D12_d1p, D12_d2p, D12_d3p, D12_d4p,
        D12_a1p, D12_a2p,
    },
    D13: {
        D13_Domek,
        D13_d1p, D13_d2p, D13_d3p, D13_d4p,
        D13_a1p, D13_a2p,
    },
    D14: {
        D14_Domek,
        D14_d1p,
        D14_a1p, D14_a2p, D14_a3p, D14_a4p,
    },
    D15: {
        D15_Domek,
        D15_d1p, D15_d2p,
        D15_a1p, D15_a2p, D15_a3p, D15_a4p,
    },
    D16: {
        D16_Domek,
        D16_d1p, D16_d2p,
        D16_a1p,
    },
};

const List1 = require("../../assets/Urszula/list_1.png");
const List2 = require("../../assets/Urszula/list_2.png");
const ListZestaw = require("../../assets/Urszula/list_zestaw.png");
const Zdjecie1 = require("../../assets/Urszula/zdjecie_1.png");
const Zdjecie2 = require('../../assets/Urszula/zdjecie_2.png');
const Ula = require("../../assets/Urszula/Ula.png");
const Piec = require("../../assets/Urszula/piec.png");
const Telefon = require("../../assets/Urszula/telefon.png");
const TelefonPrzycisk = require("../../assets/Urszula/telefon_przycisk.png");
const Tata = require("../../assets/Urszula/tata.png");
const Mama = require("../../assets/Urszula/mama.png");
const UlaDziecko = require("../../assets/Urszula/Ula_dziecko.png");
const UlaUrszulankaCzarna = require("../../assets/Urszula/Ula_Urszulanka_czarna.png");
const UlaPniewy = require("../../assets/Urszula/Ula_Pniewy.png");
const UlaSpiaca = require("../../assets/Urszula/Ula_spiaca.png");
const Kino = require("../../assets/kino.png");
const Pionki = require("../../assets/Gry/pionki.png");
const GryBaner = require("../../assets/Gry/baner.jpg");
const GryKrzyzowka = require("../../assets/Gry/pikto_krzyzowka.png");
const GryMemory = require("../../assets/Gry/pikto_memory.png");
const GryKolorowanka = require("../../assets/Gry/pikto_kolorowanka.png");
const GryRebusy = require("../../assets/Gry/pikto_rebusy.png");
const GryWycinanka = require("../../assets/Gry/pikto_wycinanka.png");
const GryTrimino = require("../../assets/Gry/pikto_trimino.png");
const GryTestCzujnosci = require("../../assets/Gry/pikto_testczujnosci.png");
const GryGraf = require("../../assets/Gry/pikto_graf.png");
const GryQUesty = require("../../assets/Gry/pikto_questy.png");
const GryMysli = require("../../assets/Gry/pikto_mysli.png");
const GryInne = require("../../assets/Gry/pikto_inne.png");
const GryPiosenki = require("../../assets/Gry/pikto_piosenki.png");
const PiosenkiOkladka1 = require("../../assets/Gry/okladka_1.jpg");
const PiosenkiOkladka2 = require("../../assets/Gry/okladka_2.jpg");
const PiosenkiOkladka3 = require("../../assets/Gry/okladka_3.png");
const D1Baner = require("../../assets/D1/baner.png");
const KaplicaZ1 = require("../../assets/D1/kaplica_z1.png");
const KaplicaZ2 = require("../../assets/D1/kaplica_z2.png");
const KaplicaZ3 = require("../../assets/D1/kaplica_z3.png");
const KaplicaZ4 = require("../../assets/D1/kaplica_z4.png");
const D1Baner2 = require("../../assets/D1/baner2.png");
const CelaBaner = require("../../assets/Cela/baner.png");
const CelaBiurko = require("../../assets/Cela/biurko.png");
const CelaFotel = require("../../assets/Cela/fotel.png");
const CelaHabit = require("../../assets/Cela/habit.png");
const CelaKlauzura = require("../../assets/Cela/klauzura.png");
const CelaNotatnik = require("../../assets/Cela/notatnik.png");
const CelaOkulary = require("../../assets/Cela/okulary.png");
const CelaRodowod = require("../../assets/Cela/rodowod.png");
const CelaStolik = require("../../assets/Cela/stolik.png");
const CelaWlosy = require("../../assets/Cela/wlosy.png");
const Podpis = require("../../assets/Urszula/podpis.png");

export {
    List1,
    List2,
    ListZestaw,
    Zdjecie1,
    Zdjecie2,
    Ula,
    Piec,
    Telefon,
    TelefonPrzycisk,
    Tata,
    Mama,
    UlaDziecko,
    UlaUrszulankaCzarna,
    UlaPniewy,
    UlaSpiaca,
    //MapaPreview,
    //Napis1,
    //Napis2,
    //Legenda,
    Pionek,
    //PionekMapa,
    Kino,
    Pionki,
    GryBaner,
    GryKrzyzowka,
    GryMemory,
    GryKolorowanka,
    GryRebusy,
    GryWycinanka,
    GryTrimino,
    GryTestCzujnosci,
    GryGraf,
    GryQUesty,
    GryMysli,
    GryInne,
    GryPiosenki,
    PiosenkiOkladka1, PiosenkiOkladka2, PiosenkiOkladka3,
    D1Baner,
    KaplicaZ1, KaplicaZ2, KaplicaZ3, KaplicaZ4,
    D1Baner2,
    CelaBaner,
    CelaBiurko,
    CelaFotel,
    CelaHabit,
    CelaKlauzura,
    CelaNotatnik,
    CelaOkulary,
    CelaRodowod,
    CelaStolik,
    CelaWlosy,
    Podpis,
};

export const obrazy = [
    List1,
    List2,
    ListZestaw,
    Zdjecie1,
    Zdjecie2,
    Ula,
    Piec,
    Telefon,
    TelefonPrzycisk,
    Tata,
    Mama,
    UlaDziecko,
    UlaUrszulankaCzarna,
    UlaPniewy,
    UlaSpiaca,
    //MapaPreview,
    //Napis1,
    //Napis2,
    //Legenda,
    Pionek,
    //PionekMapa,
    Kino,
    Pionki,
    GryBaner,
    GryKrzyzowka,
    GryMemory,
    GryKolorowanka,
    GryRebusy,
    GryWycinanka,
    GryTrimino,
    GryTestCzujnosci,
    GryGraf,
    GryQUesty,
    GryMysli,
    GryInne,
    GryPiosenki,
    PiosenkiOkladka1, PiosenkiOkladka2, PiosenkiOkladka3,
    D1Baner,
    KaplicaZ1, KaplicaZ2, KaplicaZ3, KaplicaZ4,
    D1Baner2,
    CelaBaner,
    CelaBiurko,
    CelaFotel,
    CelaHabit,
    CelaKlauzura,
    CelaNotatnik,
    CelaOkulary,
    CelaRodowod,
    CelaStolik,
    CelaWlosy,
    Podpis,
];