import React, { useState, useEffect } from 'react';
import { PixelRatio, Platform, StyleSheet, Text, useWindowDimensions } from 'react-native';
import { Animated as RNAnimated } from 'react-native';
import { shadow } from './Narzedzia';
import Animated, { debug, multiply, sub, Value } from 'react-native-reanimated';
import { usePanGestureHandler, withDecay, withOffset, diffClamp, useValue } from "react-native-redash/lib/module/v1";
import { PanGestureHandler } from 'react-native-gesture-handler';
import { Svg, Path } from 'react-native-svg';

const styles = StyleSheet.create({
    container: {
        paddingVertical: 30 / PixelRatio.get(),
        paddingHorizontal: 50 / PixelRatio.get(),
        backgroundColor: 'white',
    },
    dishShadow: {
      borderRadius: 15,
      ...shadow({
        elevation: 20,
        color: 'black',
        opacity: 0.5,
        radius: 20,
        offsetWidth: 5,
        offsetHeight: 5,
      })
    },
    title: {
        marginBottom: 10 / PixelRatio.get(),
        textAlign: 'center',
        fontFamily: 'CenturyGothic-Bold',
        fontSize: (Platform.OS === 'web') ? 18 : 12,
        ...Platform.select({ 
          "web": {letterSpacing: 4 },
          "native": {letterSpacing: 3 },
        }),
    },
    opis: {
        marginTop: 10 / PixelRatio.get(),
        textAlign: 'justify',
        fontFamily: 'CenturyGothic-Regular',
        fontSize: (Platform.OS === 'web') ? 18 : 12,
    },
    tip: {
      marginTop: 10 / PixelRatio.get(),
      textAlign: 'center',
      fontFamily: 'CenturyGothic-Regular',
      color: 'grey',
      fontSize: (Platform.OS === 'web') ? 12 : 10,
    },
});
    
export function Opis({show, style, title, opis, safeWidth, safeHeight}) {
    const windim = useWindowDimensions();
    const [ visible, setVisible ] = useState(false);
    const [ opacity ] = useState(new RNAnimated.Value(0));
    const { gestureHandler, translation, velocity, state } = usePanGestureHandler();
    const x = useValue(0);
    const y = useValue(0);
    const width = useValue(0);
    const height = useValue(0);
    const offsetX = new Value(0);
    const offsetY = new Value(0);
    const minX = multiply(-1, x);
    const minY = multiply(-1, y);
    const stopkaHeight = 64;
    const maxX = sub(sub(safeWidth, width), x);
    const maxY = sub(sub(safeHeight+stopkaHeight, height), y);
    const translateX = diffClamp(/*withDecay({value: */withOffset(translation.x, state, offsetX)/*, velocity: velocity.x, state })*/, minX, maxX);
    const translateY = diffClamp(/*withDecay({value: */withOffset(translation.y, state, offsetY)/*, velocity: velocity.y, state })*/, minY, maxY);

    useEffect(() => {
      if ( show )
      {
        setVisible(true);
        RNAnimated.timing(opacity, {
          toValue: 1, 
          duration: 400,
          ...Platform.select({ "native": { useNativeDriver: true } }),
        }).start(({finished}) => {
        });
      }
      else
      {
        //setFadeInFinished(false);
        RNAnimated.timing(opacity, {
          toValue: 0,
          duration: 400,
          ...Platform.select({ "native": { useNativeDriver: true } }),
        }).start(({finished}) => {
          setVisible(false);
        });
      }
    }, [show]);

    const layout = (e) => {
      const layout = e.nativeEvent.layout;
      //console.log(`${JSON.stringify(layout)}`);
      x.setValue(layout.x);
      y.setValue(layout.y);
      width.setValue(layout.width);
      height.setValue(layout.height);
    };

    function fontSize(size) {
      return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
    }
        
    return (
      visible && <PanGestureHandler {...gestureHandler}><Animated.View style={[style, {zIndex: 2, transform: [ {translateX}, {translateY} ]}]} onLayout={layout}>
          <RNAnimated.View style={[styles.container, { opacity }, styles.dishShadow]}>
            <Text style={[styles.title, { fontSize: fontSize(18) }]}>{title}</Text>
            <Svg width="100%" height="7" viewBox="-2 -2 317 7">
              <Path
                stroke="black" strokeWidth="2" fill="none" strokeLinecap="round"
                d="M0,.35A64.83,64.83,0,0,1,21.84,1.79c11,2.85,37.26.23,43.44-.24s24.64-1.76,30-.48,19.55,1.08,23.28,1,14.67-1,19-.24S166.63.85,169,.83,182,.1,187.2,1.55,216.31,2.86,221,2.75s21-1.26,24.24-1.92,14.72-.8,18,0,14,1,22.08,1,28.08-.72,28.08-.72"
              />
            </Svg>
            <Text style={[styles.opis, { fontSize: fontSize(18) }]}>{opis}</Text>
            {/*{Platform.OS === 'web' &&
            <Text style={styles.tip}>Kliknij i przytrzymaj lub kliknij podwójnie budynek,{"\n"}by dowiedzieć się więcej.</Text>}
            {Platform.OS !== 'web' &&
            <Text style={styles.tip}>Naciśnij i przytrzymaj budynek,{"\n"}by dowiedzieć się więcej</Text>}*/}
          </RNAnimated.View>
        </Animated.View>
      </PanGestureHandler>
    );
};
