// Maryjo Świeć

import React, { useState, useRef, useEffect } from 'react';
import { Platform, View, ScrollView, Text, Image, Animated as RNAnimated, TouchableWithoutFeedback, TouchableOpacity, Linking, PixelRatio, useWindowDimensions } from 'react-native';
import { Audio, Video } from 'expo-av';
import { useFocusEffect } from '@react-navigation/native';
import { SharedElement } from 'react-navigation-shared-element';
import { dawno, aktualnie } from './zdjeciaD0';
import { Img } from '../components/Img';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import FullWidthImage from 'react-native-fullwidth-image';
import { styles, PREFIX } from './stylesDomki';
import { O } from "../components/Obrazy";
import * as Obrazy from "../components/Obrazy";

async function openURL(url, target) {
  if ( ! url || url === "" )
    return;
  if ( Platform.OS == 'web' )
    window.open(url, target);
  else
  {
    const supported = await Linking.canOpenURL(url);
    if ( supported )
      await Linking.openURL(url);
  }
}

export const D0 = (props) => {
    const navigation = props.navigation;
    const domek = props.route.params.domek;
    const opacity = React.useMemo(() => new RNAnimated.Value(0), []);
    const [ videoHeight, setVideoHeight ] = useState(0);
    const windim = useWindowDimensions();

    function fontSize(size) {
      return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
    }

    const videoRef = useRef(null);
 
    useEffect(() => {
      //console.log("mount");
      (async ()=>{
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: false,
          staysActiveInBackground: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true,
          shouldDuckAndroid: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          playThroughEarpieceAndroid: false
        });  
      })();
    }, []);
    
    useEffect(() => async () => {
      if ( videoRef.current != null )
        await videoRef.current.stopAsync();
      /*
      if ( videoRef.current != null )
        await videoRef.current.unloadAsync();
      */
    }, []);
  
    useFocusEffect(
      React.useCallback(() => {
        // Do something when the screen is focused
        return async () => {
          // Do something when the screen is unfocused
          // Useful for cleanup functions
          if ( videoRef.current != null )
            await videoRef.current.stopAsync();
          /*
          if ( videoRef.current != null )
            await videoRef.current.unloadAsync();
          */
        };
      }, [])
    );
  
    return (
      <Kratka style={styles.container}>
      <ScrollView>
        { Platform.OS === 'web' &&
        <FullWidthImage source={O.D0.D0_Domek} />
        }
        { Platform.OS !== 'web' &&
        <Image source={O.D0.D0_Domek} style={styles.domek} />
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Image source={Obrazy.Pionek} resizeMode="contain" style={styles.pionek} />
          </View>
          <View style={[styles.content2, styles.contentOpis]}>
            <Text style={[styles.opis, { fontSize: fontSize(24) }]}>Zakupiony 11 II 1920 - od początku stanowił budynek klauzury. Tu mieściła się również pierwsza kaplica (obecnie cela Matki Założycielki i przylegająca do niej rozmównica). Dom zmieniał się przez wiele lat, jednak od początku pozostała w nim kaplica, która oczywiście też przechodziła kolejne przebudowy.</Text>
          </View>
        </View>
        { Platform.OS === 'web' &&
        <FullWidthImage source={Obrazy.CelaBaner} />
        }
        { Platform.OS !== 'web' &&
        <Image source={Obrazy.CelaBaner} style={{width: "100%", height: undefined, aspectRatio: 1920 / 606}} />
        }
        <View style={[styles.contentContainer, styles.paddingH, { marginTop: "8%" }]}>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>Szare fartuchy, które święta Urszula dostała w prezencie podczas swojego pobytu w Danii, pierwotnie miały być przeznaczone dla służących. Ale ich prosty, elegancki krój tak spodobał się siostrom, że same postanowiły, że będą w nich chodzić.</Text></View>
          <View style={styles.content1}><Image source={Obrazy.CelaHabit} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 500 / 576 }} /></View>
        </View>
        <View style={[styles.contentContainer, styles.paddingH]}>
          <View style={styles.content1}><Image source={Obrazy.CelaStolik} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 881 / 500 }} /></View>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>Nim „cztery kąty” zyskały swój piec, cele pozostawały nieogrzewane. Zimą woda, która miała służyć do porannej toalety, zamarzała i rankiem trzeba było rozbić wpierw okrywającą ją taflę lodu.</Text></View>
        </View>
        <View style={[styles.contentContainer, styles.paddingH]}>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>Urszula napisała w swoim życiu ponad 8 tysięcy listów. Część z nich powstało właśnie tutaj. Na eleganckim sekretarzyku, przy którym hrabina zawsze siedziała w pozycji wyprostowanej, panował artystyczny nieład: pióro, atrament, okulary, piętrzące się kartki z notatkami, obrazki i zdjęcia.</Text></View>
          <View style={styles.content1}><Image source={Obrazy.CelaBiurko} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 781 / 700 }} /></View>
        </View>
        <View style={[styles.contentContainer, styles.paddingH]}>
          <View style={styles.content1}><Image source={Obrazy.CelaFotel} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 610 / 500 }} /></View>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>Najbardziej oryginalny mebel w całych Pniewach! Jak większość rzeczy, Urszula dostała go w prezencie. Ale nie korzystała z niego sama. Siadały w nim głównie siostry, chcące porozmawiać ze swoja ukochaną Matuchną oraz goście odwiedzający słynną urszulankę.</Text></View>
        </View>
        <View style={[styles.contentContainer, styles.paddingH]}>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>Nie ma ludzi bez wad. Święta Urszula miała na przykład wadę wzroku. Okularów używała do czytania i pisania.</Text></View>
          <View style={styles.content1}><Image source={Obrazy.CelaOkulary} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 500 / 634 }} /></View>
        </View>
        <View style={[styles.contentContainer, styles.paddingH]}>
          <View style={styles.content1}><Image source={Obrazy.CelaNotatnik} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 500 / 569 }} /></View>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>Pamiętnik byłej uczennicy, R. Bandrowskiej, do któego Matka wpisała: „Biała nasza Matko Boska, zachowaj serca nasze białe, czyste i niewinne. O modlitwę prosi M. Urszula, urszulanka, 6.6.905”</Text></View>
        </View>
        <View style={[styles.contentContainer, styles.paddingH]}>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>Drzewo genealogiczne Julii Ledóchowskiej wykonane własnoręcznie przez M. Urszulę w 1885 r.</Text></View>
          <View style={styles.content1}><Image source={Obrazy.CelaRodowod} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 643 / 700 }} /></View>
        </View>
        <View style={[styles.contentContainer, styles.paddingH, { marginBottom: "8%" }]}>
          <View style={styles.content1}><Image source={Obrazy.CelaWlosy} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 500 / 697 }} /></View>
          <View style={[styles.content1, {alignItems: "flex-start"}]}>
            <Text style={[styles.opis, { fontSize: fontSize(24) }]}>Warkocz Matki z 1923 r.</Text>
            <Text style={[styles.opis, { fontSize: fontSize(24) }]}>Metalowa taca ozdobna, przekrój 25 cm.</Text>
          </View>
        </View>
{/*
        <View style={[styles.contentContainer, styles.paddingH, {backgroundColor: "#c9c9c9"}]}>
            <View style={[styles.galeria, {flexDirection: "row"}]}>
                {aktualnie.map((zdjecie, i) => 
                <TouchableWithoutFeedback key={"z"+i} onPress={() => navigation.push("Zdjecie", { zdjecie })}>
                  <SharedElement id={zdjecie.id}>
                    <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} />
                  </SharedElement>
                </TouchableWithoutFeedback>
                )}
            </View>
        </View>
*/}
        { Platform.OS === 'web' &&
        <FullWidthImage source={Obrazy.CelaKlauzura} />
        }
        { Platform.OS !== 'web' &&
        <Image source={Obrazy.CelaKlauzura} style={{width: "100%", height: undefined, aspectRatio: 1920 / 632}} />
        }
        <View style={[styles.paddingH, {marginVertical: "4%", alignItems: "center"}]}>
          <Text style={[styles.opis, { fontSize: fontSize(24) }]}>Co jeszcze znajduje się w budynku Maryjo Świeć?</Text>
          <Text style={[styles.opis, { fontSize: fontSize(24) }]}>To nasza słodka tajemnica! Aby przejść przez drzwi z napisem „KLAUZURA”, trzeba być urszulanką.</Text>
          <Text style={[styles.opis, { fontSize: fontSize(24), marginTop: "2%" }]}>Więcej informacji:</Text>
          <TouchableOpacity onPress={()=>openURL("https://urszulanki.pl/powolanie/etapy-formacji", "_blank")}><Text style={[styles.opis, { fontSize: fontSize(24) }]}>www.urszulanki.pl/powolanie/etapy-formacji</Text></TouchableOpacity>
        </View>
        { Platform.OS === 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <FullWidthImage source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View onContextMenu={(e)=>{e.preventDefault();}} style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/D0.mp4", width: 1920, height: 1080 }}
              /*posterSource={require("../../assets/test.jpg")}
              usePoster*/
              onLoad={()=>{
                document.querySelector("video").controlsList = "nodownload";
                RNAnimated.timing(opacity, {
                toValue: 1,
                ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }]}
            />
          </RNAnimated.View>
        </View>
        }
        { Platform.OS !== 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <Image source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/D0m.mp4", width: 1280, height: 720 }}
              /*posterSource={require("../../assets/test.jpg")}
              usePoster*/
              onLoad={()=>{RNAnimated.timing(opacity, {
                toValue: 1,
                ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }]}
            />
          </RNAnimated.View>
        </View>
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Dawno, dawno temu…</Text>
          </View>
          <View style={[styles.content2, styles.galeria]}>
            {dawno.map((zdjecie, i) => 
              <TouchableWithoutFeedback key={"zd"+i} onPress={() => navigation.navigate("Zdjecie", { zdjecie, indeks: i, zdjecia: dawno })}>
                <SharedElement id={zdjecie.id} style={styles.zdjecie}>
                  <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                </SharedElement>
              </TouchableWithoutFeedback>
            )}
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Aktualnie…</Text>
          </View>
          <View style={[styles.content2, styles.galeria]}>
            {aktualnie.map((zdjecie, i) => 
              <TouchableWithoutFeedback key={"za"+i} onPress={() => navigation.navigate("Zdjecie", { zdjecie, indeks: i, zdjecia: aktualnie })}>
                <SharedElement id={zdjecie.id} style={styles.zdjecie}>
                  <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                </SharedElement>
              </TouchableWithoutFeedback>
            )}
          </View>
        </View>
        <Stopka />
      </ScrollView>
      </Kratka>
    );
}
