import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D2/";
const PREVIEW = O.D2;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D2_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1217, height: 880},
      opis: '',
    },
    {
      id: 2,
      preview: PREVIEW.D2_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 1155, height: 661},
      opis: '',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D2_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 2144, height: 1424},
    opis: '',
  },
  {
    id: 102,
    preview: PREVIEW.D2_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 2736, height: 1824},
    opis: '',
  },
];
