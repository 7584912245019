import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D15/";
const PREVIEW = O.D15;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D15_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 747, height: 371},
      opis: 'Rok 1922',
    },
    {
      id: 2,
      preview: PREVIEW.D15_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 1013, height: 999},
      opis: 'W latah 1932-1978',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D15_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Józefa, stan obecny',
  },
  {
    id: 102,
    preview: PREVIEW.D15_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1275},
    opis: 'Widok od ulicy',
  },
  {
    id: 103,
    preview: PREVIEW.D15_a3p,
    zdjecie: {uri: PATH + "a3.jpg", width: 1440, height: 1920},
    opis: 'Święty Józef',
  },
  {
    id: 104,
    preview: PREVIEW.D15_a4p,
    zdjecie: {uri: PATH + "a4.jpg", width: 1440, height: 1920},
    opis: 'Święty Olaf - patron miejsca',
  },
];
