import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D13/";
const PREVIEW = O.D13;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D13_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1611, height: 1048},
      opis: 'Budowa obory i domu św. Antoniego, r. 1926',
    },
    {
      id: 2,
      preview: PREVIEW.D13_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 1920, height: 1440},
      opis: '5 V 1980 odbyło się poświęcenie domu św. Antoniego (dawnej mleczarni) oraz nowej obory',
    },
    {
      id: 3,
      preview: PREVIEW.D13_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 1275, height: 1920},
      opis: 'Dom św. Antoniego',
    },
    {
      id: 4,
      preview: PREVIEW.D13_d4p,
      zdjecie: {uri: PATH + "d4.jpg", width: 1177, height: 1713},
      opis: 'Św. Antoni. Obraz w kaplicy sióstr urszulanek w Krakowie',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D13_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Antoniego, r. 2017',
  },
  {
    id: 102,
    preview: PREVIEW.D13_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Antoniego, stan obecny',
  },
];
