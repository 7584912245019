import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
//import { useWindowDimensions } from 'react-native';
//import { ScrollView } from 'react-native-gesture-handler';
//import Example from './ImageViewer';
import { Feather } from '@expo/vector-icons';
import DrawerContent from './src/DrawerContent';
import Sanktuarium from './src/Sanktuarium';
import Urszula from './src/Urszula';
import Mysl from './src/Mysl';
import Gry from './src/Gry';
//import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context';
//import Loader from './src/components/Loader';
import * as Font from "expo-font";
import { useFonts } from 'expo-font';
import { useAssets } from 'expo-asset';
import { Sacramento_400Regular } from '@expo-google-fonts/sacramento';
import { O } from "./src/components/Obrazy";
import * as Analytics from 'expo-firebase-analytics';
import Loader from "./src/components/Loader";
import * as ScreenOrientation from 'expo-screen-orientation';

const Drawer = createDrawerNavigator();

// Get the current screen from the navigation state
function getActiveRouteName(navigationState) {
  if ( ! navigationState ) return null;
  const route = navigationState.routes[navigationState.index];
  // Parse the nested navigators
  if ( route.routes ) return getActiveRouteName(route);
  return route.routeName;
}

const linking = {
  config: {
    screens: {
      Sanktuarium: {
//        initialRouteName: "SanktuariumScreen",
        screens: {
          SanktuariumScreen: { },
          D0: { },
          D1: { },
          D2: { },
          D3: { },
          D4: { },
          D5: { },
          D6: { },
          D7: { },
          D8: { },
          D9: { },
          D10: { },
          D11: { },
          D12: { },
          D13: { },
          D14: { },
          D15: { },
          D16: { },
          Bajka: { path: "bajka" },
          Polityka: { path: "polityka" },
        }
      },
      Urszula: {
        path: "urszula",
        screens: { UrszulaScreen: { }}
      },
      Mysl: {
        path: "mysl",
        screens: { MyslScreen: { }}
      },
      Gry: {
        path: "gry",
        screens: { GryScreen: { }}
      },
    }
  }
}

export default function() {
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();

  const fonts = {
    Sacramento_400Regular,
    "CenturyGothic-Bold": require("./assets/fonts/gothicb.ttf"),
    "CenturyGothic-BoldItalic": require("./assets/fonts/gothicbi.ttf"),
    "CenturyGothic-Regular": require("./assets/fonts/gothic.ttf"),
    "CenturyGothic-Italic": require("./assets/fonts/gothici.ttf"),
  };

  let [ assetsLoaded, assetsLoadingError ] = useAssets(O.SanktuariumArray);
  let [ fontsLoaded, fontsLoadingError ] = useFonts(fonts);

  async function _loadFontsAsync() {
    try {
      await Font.loadAsync(fonts);
    } catch ( error ) {
      //console.warn("Font.loadAsync error: ", error);
    }
  };

  !! assetsLoadingError && console.warn("assetsLoadingError: ", assetsLoadingError);
  if ( !! fontsLoadingError )
  {
    console.warn("fontsLoadingError: ", fontsLoadingError);
    _loadFontsAsync();
    fontsLoaded = true;
  }

  if ( !! assetsLoadingError )
    assetsLoaded = true;

  if ( ! assetsLoaded || ! fontsLoaded )
  {
    console.log("Pobieram dane...");
    return (
      <View style={styles.loader}>
        <Loader />
      </View>
    );
  }

  if ( Platform.OS !== "web" )
  {
    (async () => {
      await ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
    })();
  }

  console.log("Dane pobrane.");
  return (
/*    <SafeAreaProvider initialMetrics={initialWindowMetrics}>*/
    <NavigationContainer
      ref={navigationRef}
      linking={linking}
      onReady={() => routeNameRef.current = navigationRef.current.getCurrentRoute().name}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name

        if (previousRouteName !== currentRouteName) {
/*
            if ( Platform.OS !== "web" )
            {
              await Analytics.logEvent('Ekran', {
                name: 'screen',
                screen: currentRouteName,
              }).catch();
            }
*/
            await Analytics.setCurrentScreen(currentRouteName).catch();
        }

        routeNameRef.current = currentRouteName;
      }}>
      <Drawer.Navigator
        drawerContentOptions={{
          activeTintColor: '#e91e63',
          labelStyle: { fontFamily: 'CenturyGothic-Bold' },
          //itemStyle: { marginVertical: 30 },
        }}
        //drawerPosition='right'
        drawerStyle={{backgroundColor: 'white'}} 
        overlayColor={'rgba(0, 0, 0, 0.8)'} 
        //hideStatusBar={true} 
        //statusBarAnimation={true} 
        drawerContent={props => DrawerContent({...props})}>
        <Drawer.Screen name="Sanktuarium" component={Sanktuarium} options={{ drawerLabel: 'Sanktuarium', drawerIcon: ({ focused, color, size }) => <Feather name='home' color={color} size={size} /> }} />
        <Drawer.Screen name="Urszula" component={Urszula} options={{ drawerLabel: 'Święta Urszula', drawerIcon: ({ focused, color, size }) => <Feather name='user' color={color} size={size} /> }} />
        <Drawer.Screen name="Mysl" component={Mysl} options={{ drawerLabel: 'Myśl na dziś', drawerIcon: ({ focused, color, size }) => <Feather name='calendar' color={color} size={size} /> }} />
        <Drawer.Screen name="Gry" component={Gry} options={{ drawerLabel: 'Gry i zabawy', drawerIcon: ({ focused, color, size }) => <Feather name='star' color={color} size={size} /> }} />
{/*
        <Drawer.Screen name="Listownia" component={Listownia} options={{ drawerLabel: 'Listownia', drawerIcon: ({ focused, color, size }) => <Feather name='at-sign' color={color} size={size} /> }} />
        <Drawer.Screen name="Ksiega" component={Ksiega} options={{ drawerLabel: 'Księga gości', drawerIcon: ({ focused, color, size }) => <Feather name='book-open' color={color} size={size} /> }} />
*/}
      </Drawer.Navigator>
    </NavigationContainer>
/*    </SafeAreaProvider>*/
  );
}

const styles = StyleSheet.create({
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
  },
});