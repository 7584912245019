import React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
//import { createStackNavigator } from '@react-navigation/stack';
import { LinearGradient } from 'expo-linear-gradient';
//import { SafeAreaView } from 'react-native-safe-area-context';
//import { useWindowDimensions } from '../components/Utility';
import { headerStyles, LogoTitle } from '../components/LogoTitle';
import { domkiScreens } from './Domki';
import Mapa from './Mapa';
import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import Zdjecie from './Zdjecie';
import Bajka from "./Bajka";
import Polityka from "./Polityka";
/*
function SanktuariumScreen({navigation, route}) {
    return (
            <Mapa navigation={navigation} route={route} />
    );
    return (
        <LinearGradient colors={["rgba(187,203,203, 1)", "rgba(255,255,255, 1)"]} start={[0.45, 0.1]} end={[0.55, 0.6]} style={styles.container}>
            <Mapa navigation={navigation} route={route} />
        </LinearGradient>
    );
};
*/
const Stack = createSharedElementStackNavigator();

const Sanktuarium = ({navigation, route}) => {
    return (
        <Stack.Navigator
            screenOptions={{
                headerBackTitleVisible: false,
                headerStyle: headerStyles.header,
                headerTintColor: 'white',
                headerTitleStyle: headerStyles.headerTitle,
                headerTitleContainerStyle: headerStyles.headerContainer,
                headerTitle: props => <LogoTitle {...props} navigation={navigation}>Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach</LogoTitle>,
                title: "Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach",
                /*headerRight: () => (
                    <TouchableOpacity onPress={() => navigation.openDrawer()} style={headerStyles.headerRight}>
                    <Feather color="white" name='menu' size={36} />
                    </TouchableOpacity>
                ),*/
            }}>
        <Stack.Screen name="SanktuariumScreen" component={Mapa} />
        {domkiScreens.map((domek, index) => 
            ( ! domek.dummy ) && <Stack.Screen key={domek.name} name={domek.name} component={domek.comp} />
        )}
        <Stack.Screen name="Zdjecie" component={Zdjecie} options={{ /*animationEnabled: true,*/ }}
          sharedElements={(route, otherRoute, showing) => {
            const zdjecie = route.params.zdjecie;
            return [{
                id: zdjecie.id,
                //animation: Platform.OS === 'web' ? 'fade' : 'move',
                animation: "move",
                resize: "auto",
                //align: 'center-bottom',
              }];
          }}
        />
        <Stack.Screen name="Bajka" component={Bajka} />
        <Stack.Screen name="Polityka" component={Polityka} />
        </Stack.Navigator>
    );
}

export default Sanktuarium;