import React from 'react'
import { Platform, View, Image, Text, StyleSheet, PixelRatio, useWindowDimensions } from 'react-native';
import Animated, { Easing, useCode, Value, block, cond, set, clockRunning, startClock, stopClock, timing, not, multiply } from 'react-native-reanimated';
import { useClock, useValue } from 'react-native-redash/lib/module/v1';
//import { SafeAreaView } from 'react-native-safe-area-context';
//import { useWindowDimensions } from '../components/Utility';
import { createStackNavigator } from '@react-navigation/stack';
import { headerStyles, LogoTitle } from '../components/LogoTitle';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import * as Obrazy from "../components/Obrazy";

const mysli = [
  {cytat: 'Potrzeba nam świętej radości. Dusza bez radości – to dusza bez siły, bez odwagi, bez energii.'},
  {cytat: 'Radość jest nam tak potrzebna, jak kwiatu promienie słoneczne.'},
  {cytat: 'Obowiązek, chociaż trudny i męczący, jeżeli jest dobrze wykonany, zawsze daje radość i zadowolenie.'},
  {cytat: 'Niech poczucie obowiązku będzie fundamentem Waszego życia.'},
  {cytat: 'Grzeczność domaga się tylko trochę serca, trochę dobrej woli, z pragnieniem, by innym przyjemność zrobić.'},
  {cytat: 'Idźcie w świat z uśmiechem na ustach, idźcie rozsiewać trochę szczęścia po tej łez dolinie, uśmiechając się do wszystkich, ale szczególnie do smutnych…'},
  {cytat: 'Idźcie naprzód – o ile tylko możecie – odważnie, ufnie, pogodnie!'},
  {cytat: 'Pogoda ducha jest dla duszy tym, czym słońce dla ziemi.'},
  {cytat: 'Serce czyste ma skrzydła, nieustannie wznosi się wyżej, wyżej ponad tę ziemię, ciągle wyżej – sursum corda…'},
  {cytat: 'Bądźmy święte, a więcej zrobimy dla świata niż choćby najsławniejsi wodzowie.'},
  {cytat: 'Miłość nie polega na wizytach, ale na oddawaniu siebie…'},
  {cytat: 'Dobroci, dobroci, dobroci potrzeba! Dobrocią przezwyciężysz wszystkie trudności, znajdziesz drogę do każdego serca.'},
  {cytat: 'Zbieraj sobie skarby miłości – zabierzesz je ze sobą do nieba!'},
  {cytat: '…chciej swą pogodą ducha, uśmiechem na ustach być promykiem słonecznym dla swego otoczenia…'},
  {cytat: 'Nie wiecie, ile szczęścia dajecie nieraz uśmiechem miłości, słowem dobroci!'},
  {cytat: 'Miłość oparta tylko na uczuciu – to bańka mydlana, bez wartości, bez treści.'},
  {cytat: 'Twarz, na której stale bawi uśmiech jasny, serdeczny, wywiera swój cichy, zbawienny wpływ na otaczających.'},
  {cytat: 'Uśmiech na twojej twarzy wywołuje uśmiech na innych twarzach'},
  {cytat: 'Nie zniechęcajcie się, choć trudno, choć nie od razu się udaje. Próbujmy, próbujmy ciągle, a dojdziemy!'},
  {cytat: 'Ufności nam potrzeba, dziecięcej ufności, która serca napełnia pogodą i spokojem…'},
  {cytat: 'Kochać bliźniego to znaczy chcieć mu życie rozjaśnić, osłodzić, uprzyjemnić.'},
  {cytat: 'Ile słodyczy zawiera to słowo: przyjaźń, przyjaciel! W nim mieści się cały ogrom miłości stałej, wiernej, głębokiej i zarazem spokojnej.'},
  {cytat: 'Miejmy serce szerokie w dobroci…'},
  {cytat: 'Dobre serce umie wyczuwać potrzeby drugiego serca…'},
  {cytat: 'Trzeba stawiać sobie wysokie ideały, do nich dążyć, ich się trzymać.'},
  {cytat: 'Uśmiech rozprasza chmury nagromadzone w duszy.'},
  {cytat: 'Nie patrzę w przeszłość ani w przyszłość, wzrok mam utkwiony w górę!'},
  {cytat: 'Bądźmy niby pióro w rękach Jezusa…'},
  {cytat: 'Zamykam oczy, a Ty prowadź!'},
  {cytat: 'Czasem jednym ciastkiem można tyle dobrego zrobić.'},
  {cytat: 'Czy dość jadasz? – kup sobie czekoladę.'},
  {cytat: 'Pamiętaj, bądź słodka jak miód.'},
  {cytat: 'Jak słodkie jest życie w towarzystwie Jezusa.'},
  {cytat: 'Uśmiech jest wypoczynkiem.'},
  {cytat: 'To zapisz sobie, a ja załatwię.'},
  {cytat: 'Trzeba zrobić, co można dobrocią, łagodnością…'},
  {cytat: 'Święte Boże wesele jest najlepszym lekarstwem na złe humory, na rozdrażnienie nerwowe.'},
  {cytat: 'W Jezusie zawsze się spotykamy…'},
  {cytat: 'Im ciemniej naokoło, tym jaśniej musi nam świecić światło nadziei.'},
  {cytat: 'Miłość zawsze czuwa, zawsze ma otwarte oczy na potrzeby bliźnich, zawsze szuka, czym by innym zrobić przyjemność.'},
  {cytat: 'Mam czas na różne wizyty, five o’clocki, spacery, a Jezusowi kilka minut skąpię.'},
  {cytat: 'Czas leci, leci – coraz bliżej wieczności.'},
  {cytat: 'Kochać bliźniego, to znaczy chcieć mu życie rozjaśnić, osłodzić, uprzyjemnić.'},
  {cytat: 'Dobre serce umie wyczuwać potrzeby drugiego serca.'},
  {cytat: 'Niech w sercach naszych zapali się ogień świętej Bożej miłości, a potrafimy innych rozgrzać swym ciepłem.'},
  {cytat: 'Palić, spalić się miłością.'},
  {cytat: 'Idźcie rozsiewać trochę szczęścia.'},
  {cytat: 'Mamy modlitwę, świętą nić łączącą dusze z jednego krańca ziemi do drugiego.'},
  {cytat: 'Pokora jest igłą, która dużo dziur zaceruje.'},
  {cytat: 'Czasem trochę tęskno: na to plasterek: jak Bóg chce.'},
  {cytat: 'Święty to przyjaciel.'},
  {cytat: 'I miejcie mi dobry apetyt.'},
  {cytat: 'Byłam tylko pionkiem na szachownicy…'},
  {cytat: 'Służyć Panu – to znaczy ciągle wpatrywać się w Jego oczy…'},
  {cytat: 'Jezus jest mi Przyjacielem. Ile słodyczy zawiera to słowo: przyjaźń, przyjaciel! W nim mieści się cała przepaść miłości tak stałej, tak wiernej, tak głębokiej i zarazem tak spokojnej.'},
  {cytat: 'Trzymać się chcę blisko Jezusa, w przekonaniu, że On jest moim najwierniejszym Przyjacielem!'},
  {cytat: 'Uważaj na swoje zdrowie, żyj rozsądnie, odżywiaj się racjonalnie.'},
  {cytat: 'Nie trap się zbytnio, bądź dobrej myśli.'},
  {cytat: 'Zaufaj mocno Bogu, a On pokieruje wszystkim dla Twojego dobra.'},
  {cytat: 'Tylko zachowaj spokój.'},
  {cytat: 'Pragnę wiedzieć, że jesteś spokojna, wesoła i zadowolona.'},
  {cytat: 'Im wierniej  będziemy trwać przy Bogu, tym bardziej On zatroszczy się o wszystko, co leży nam na sercu.'},
  {cytat: 'Serca ludzkie są w Jego ręku!'},
  {cytat: 'On zawsze przychodzi z pomocą, a im większe ma się do Niego zaufanie, tym lepiej wszystko wychodzi!'},
  {cytat: 'Niebo nas czeka, więc wyżej serce.'},
  {cytat: 'Nie trać odwagi i ufności.'},
  {cytat: 'Pokładaj swą ufność w Bogu, On wszystkim jak najlepiej pokieruje.'},
  {cytat: 'Ciesz się ile możesz, Pan Bóg chce, żebyśmy się w Nim radowali.'},
  {cytat: 'Zobaczysz, wszystko pójdzie dobrze, mam tę mocną nadzieję…'},
  /*
  {cytat: ''},
  {cytat: ''},
  {cytat: ''},
  {cytat: ''},
  {cytat: ''},
  {cytat: ''},
  {cytat: ''},
  */
//  {cytat: ''},
];

const nr = (Math.round(Math.random() * (mysli.length-1)));

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: 'center',
        overflow: 'hidden',
    },
    cytatContainer: {
        margin: "10%",
        alignItems: 'flex-end',
        justifyContent: 'center',
    },
    cytat: {
        textAlign: 'justify',
        fontFamily: 'CenturyGothic-Italic',
        fontWeight: 'normal',
    },
    autor: {
        marginTop: "2%",
        resizeMode: "contain",
        ...Platform.select({
          "web": {
            width: 300,
            height: 30,
          },
          "native": {
            width: "30%",
            height: undefined,
            aspectRatio: 300 / 30,
          }
        })
    },
});

const DURATION = 1000;

export function LosowaMysl() {
  const nr = (Math.round(Math.random() * (mysli.length-1)));
  return mysli[nr].cytat;
}

function runTiming(clock, value, dest) {
    const state = {
      finished: new Value(0),
      position: new Value(0),
      time: new Value(0),
      frameTime: new Value(0),
    };
  
    const config = {
      duration: DURATION,
      toValue: new Value(dest),
      easing: Easing.inOut(Easing.ease),
    };
  
    return block([
      cond(
        not(clockRunning(clock)),
          [
            set(state.position, value),
            startClock(clock),
          ]
      ),
      // we run the step here that is going to update position
      timing(clock, state, config),
      // if the animation is over we stop the clock
      cond(state.finished, stopClock(clock)),
      // we made the block return the updated position
      state.position,
    ]);
}

const MyslScreen = ({navigation, route}) => {
  const windim = useWindowDimensions();
  const clock = useClock();
  const transX = useValue(-windim.width);
  const transX2 = useValue(windim.width);

  useCode(() => [
    set(transX, runTiming(clock, transX, 0)),
    set(transX2, multiply(-1, transX)),
  ], []);

  function fontSize(size) {
    return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
  }

  return (
    <Kratka style={[styles.container, { backgroundColor: "white" }]}>
      <View style={styles.container}>
        <View style={styles.cytatContainer}>
          <Animated.View style={{transform: [{ translateX: transX }] }}><Text style={[styles.cytat, { fontSize: fontSize(36) }]}>{mysli[nr].cytat}</Text></Animated.View>
          <Animated.View style={{transform: [{ translateX: transX2 }] }}><Image source={Obrazy.Podpis} style={styles.autor} /></Animated.View>
        </View>
      </View>
      <Stopka />
    </Kratka>
  );
}

const Stack = createStackNavigator();

const Mysl = ({navigation, route}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerStyle: headerStyles.header,
        headerTintColor: 'white',
        headerTitleStyle: headerStyles.headerTitle,
        headerTitleContainerStyle: headerStyles.headerContainer,
        headerTitle: props => <LogoTitle {...props} navigation={navigation}>Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach</LogoTitle>,
        title: "Multimedialna platforma Sanktuarium św. Urszuli Ledóchowskiej w Pniewach",
      }}>
      <Stack.Screen name="MyslScreen" component={MyslScreen} />
    </Stack.Navigator>
  );
}

export default Mysl;