import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D14/";
const PREVIEW = O.D14;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D14_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1611, height: 1048},
      opis: 'Obora, r. 1926',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D14_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1527},
    opis: 'Jadalnia św. Jana Pawła II',
  },
  {
    id: 102,
    preview: PREVIEW.D14_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1440},
    opis: 'Jadalnia św. Jana Pawła II',
  },
  {
    id: 103,
    preview: PREVIEW.D14_a3p,
    zdjecie: {uri: PATH + "a3.jpg", width: 1920, height: 1440},
    opis: 'Wnętrze jadalni św. Jana Pawła II',
  },
  {
    id: 104,
    preview: PREVIEW.D14_a4p,
    zdjecie: {uri: PATH + "a4.jpg", width: 1920, height: 1440},
    opis: 'Pniewskie smakołyki',
  },
];
