import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D8/";
const PREVIEW = O.D8;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D8_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 1695, height: 1117},
      opis: 'Dom św. Kalasantego, r. 1930',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D8_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Kalasantego, stan obecny',
  },
  {
    id: 102,
    preview: PREVIEW.D8_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1920, height: 1440},
    opis: 'Dom św. Kalasantego, stan obecny',
  },
];
