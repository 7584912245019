import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D1/";
const PREVIEW = O.D1;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D1_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 591, height: 1009},
      opis: '',
    },
    {
      id: 2,
      preview: PREVIEW.D1_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 1861, height: 2669},
      opis: '',
    },
    {
      id: 3,
      preview: PREVIEW.D1_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 771, height: 1271},
      opis: '',
    },
    {
      id: 4,
      preview: PREVIEW.D1_d4p,
      zdjecie: {uri: PATH + "d4.jpg", width: 1846, height: 1287},
      opis: '',
    },
    {
      id: 5,
      preview: PREVIEW.D1_d5p,
      zdjecie: {uri: PATH + "d5.jpg", width: 1550, height: 957},
      opis: '',
    },
];

export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D1_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1713, height: 1145},
    opis: '',
  },
  {
    id: 102,
    preview: PREVIEW.D1_a2p,
    zdjecie: {uri: PATH + "a2.jpg", width: 1536, height: 1022},
    opis: '',
  },
  {
    id: 103,
    preview: PREVIEW.D1_a3p,
    zdjecie: {uri: PATH + "a3.jpg", width: 1824, height: 1368},
    opis: '',
  },
  {
    id: 104,
    preview: PREVIEW.D1_a4p,
    zdjecie: {uri: PATH + "a4.jpg", width: 1729, height: 1179},
    opis: '',
  },
  {
    id: 105,
    preview: PREVIEW.D1_a5p,
    zdjecie: {uri: PATH + "a5.jpg", width: 1368, height: 1824},
    opis: '',
  },
  {
    id: 106,
    preview: PREVIEW.D1_a6p,
    zdjecie: {uri: PATH + "a6.jpg", width: 1824, height: 1368},
    opis: '',
  },
];
