import React from 'react';
import { StyleSheet } from 'react-native';
import AnimatedLoader from 'react-native-animated-loader';

export default class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: true };
  }
 
  render() {
    const { visible } = this.state;
    return (
      <AnimatedLoader
        visible={visible}
        //overlayColor="rgba(255,255,255,0.75)"
        overlayColor="white"
        source={require("../../assets/urszula.json")}
        animationType="slide"
        animationStyle={styles.lottie}
        speed={1}
      />
    );
  }
}
 
const styles = StyleSheet.create({
  lottie: {
    width: 400,
    height: 400
  }
});