import React from 'react'
import { StyleSheet, ScrollView, Text, Image, Dimensions } from 'react-native';
import { SharedElement } from 'react-navigation-shared-element';

const styles = StyleSheet.create({
    zdjecie: {
        width: Dimensions.get("window").width,
        height: Dimensions.get("window").height - 80,
        resizeMode: 'contain',
        alignSelf: 'center',
        margin: 5
    },
})

const Zdjecie = ({navigation, route}) => {
    const zdjecie = route.params.zdjecie;
    //console.log("z: ", zdjecie.id);
    return (
        <ScrollView>
            <SharedElement id={zdjecie.id}>
                 <Image source={zdjecie.zdjecie} style={styles.zdjecie} />
            </SharedElement>
        </ScrollView>
    );
}

export default Zdjecie;