import { PREFIX } from "./stylesDomki";
import { O } from "../components/Obrazy";

const PATH = PREFIX + "piecpiaty.pl/assets/D4/";
const PREVIEW = O.D4;

export const dawno = [
    {
      id: 1,
      preview: PREVIEW.D4_d1p,
      zdjecie: {uri: PATH + "d1.jpg", width: 677, height: 464},
      opis: 'Św. Urszula z dziećmi',
    },
    {
      id: 2,
      preview: PREVIEW.D4_d2p,
      zdjecie: {uri: PATH + "d2.jpg", width: 314, height: 500},
      opis: 'Św. Urszula z dziećmi',
    },
    {
      id: 3,
      preview: PREVIEW.D4_d3p,
      zdjecie: {uri: PATH + "d3.jpg", width: 475, height: 705},
      opis: 'Św. Urszula z chłopcem',
    },
    {
      id: 4,
      preview: PREVIEW.D4_d4p,
      zdjecie: {uri: PATH + "d4.jpg", width: 1600, height: 1065},
      opis: 'Przedszkole św. Rodziny, r. 1999',
    },
];
export const aktualnie = [
  {
    id: 101,
    preview: PREVIEW.D4_a1p,
    zdjecie: {uri: PATH + "a1.jpg", width: 1920, height: 1440},
    opis: 'Przedszkole św. Rodziny, stan obecny',
  },
];
