// Kaplica

import React, { useState, useRef, useEffect } from 'react';
import { Platform, View, ScrollView, Text, Image, Animated as RNAnimated, TouchableWithoutFeedback, TouchableOpacity, PixelRatio, useWindowDimensions } from 'react-native';
import { Audio, Video } from 'expo-av';
import { useFocusEffect } from '@react-navigation/native';
import { SharedElement } from 'react-navigation-shared-element';
import { dawno, aktualnie } from './zdjeciaD1';
import { Img } from '../components/Img';
import { Kratka } from '../components/Kratka';
import { Stopka } from '../components/Stopka';
import FullWidthImage from 'react-native-fullwidth-image';
import { styles, PREFIX } from './stylesDomki';
import { O } from "../components/Obrazy";
import * as Obrazy from "../components/Obrazy";

export const D1 = (props) => {
    const navigation = props.navigation;
    const domek = props.route.params.domek;
    const opacity = React.useMemo(() => new RNAnimated.Value(0), []);
    const [ videoHeight, setVideoHeight ] = useState(0);
    const windim = useWindowDimensions();

    function fontSize(size) {
      return Math.floor(size * ( windim.width > windim.height ? windim.width : windim.height ) / (Platform.OS === "web" ? 1600 : 1200));
    }

    const videoRef = useRef(null);

    useEffect(() => {
      //console.log("mount");
      (async ()=>{
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: false,
          staysActiveInBackground: false,
          interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
          playsInSilentModeIOS: true,
          shouldDuckAndroid: true,
          interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
          playThroughEarpieceAndroid: false
        });  
      })();
    }, []);
    
    useEffect(() => async () => {
      if ( videoRef.current != null )
        await videoRef.current.stopAsync();
      /*
      if ( videoRef.current != null )
        await videoRef.current.unloadAsync();
      */
    }, []);
  
    useFocusEffect(
      React.useCallback(() => {
        // Do something when the screen is focused
        return async () => {
          // Do something when the screen is unfocused
          // Useful for cleanup functions
          if ( videoRef.current != null )
            await videoRef.current.stopAsync();
          /*
          if ( videoRef.current != null )
            await videoRef.current.unloadAsync();
          */
        };
      }, [])
    );
  
    return (
      <Kratka style={styles.container}>
      <ScrollView>
        { Platform.OS === 'web' &&
        <FullWidthImage source={O.D1.D1_Domek} />
        }
        { Platform.OS !== 'web' &&
        <Image source={O.D1.D1_Domek} style={styles.domek} />
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Image source={Obrazy.Pionek} resizeMode="contain" style={styles.pionek} />
          </View>
          <View style={[styles.content2, styles.contentOpis]}>
            <Text style={[styles.opis, { fontSize: fontSize(24) }]}>Kaplica to serce domu i wyjątkowe miejsce dla wszystkich urszulanek. Nie tylko dlatego, że kamień węgielny pod budowę kaplicy kładła 22 sierpnia 1921 roku sama święta Urszula, ale tu też w ozdobnym sarkofagu spoczywa dziś jej ciało.</Text>
          </View>
        </View>
        { Platform.OS === 'web' &&
        <FullWidthImage source={Obrazy.D1Baner} />
        }
        { Platform.OS !== 'web' &&
        <Image source={Obrazy.D1Baner} style={{width: "100%", height: undefined, aspectRatio: 1920 / 699}} />
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}><Image source={Obrazy.KaplicaZ1} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 600 / 600 }} /></View>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }, styles.paddingR]}>Po 50 latach od śmierci postanowiono przewieźć relikwie Matki Urszuli z Rzymu do Pniew. Jakie było zdumienie wszystkich, gdy po otwarciu trumny okazało się, że ciało świętej nie uległo zniszczeniu!</Text></View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }, styles.paddingL]}>W uroczystej podróży relikwii do ukochanych Pniew Urszula odwiedziła po drodze prawie wszystkie domy zgromadzenia.</Text></View>
          <View style={styles.content1}><Image source={Obrazy.KaplicaZ2} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 600 / 600 }} /></View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.content1}><Image source={Obrazy.KaplicaZ3} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 600 / 600 }} /></View>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }, styles.paddingR]}>Święta Urszula spoczywa w sarkofagu, który jest jednocześnie ołtarzem,  u stóp Ukrzyżowanego Jezusa, którego sama malowała jeszcze w Krakowie.</Text></View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.content1}><Text style={[styles.opis, { fontSize: fontSize(24) }, styles.paddingL]}>„Malując obraz Ukrzyżowanego, który do niedawna wisiał jeszcze w ciemnej kaplicy w klasztorze krakowskim, ciągle sobie powtarzałam, że ten Ukrzyżowany, którego maluję, patrzeć się będzie na mnie, gdy leżeć będę w trumnie…”</Text></View>
          <View style={styles.content1}><Image source={Obrazy.KaplicaZ4} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() * 600 / 600 }} /></View>
        </View>
        {/*
        <View style={[styles.contentContainer, { height: 300 / PixelRatio.get(), backgroundColor: "#c9c9c9" } ]}>
          <View style={{ alignItems: "center", justifyContent: "center", flex: 1}}>
            <Text style={styles.tytulWiekszy}>Zajrzyj do środka</Text>
          </View>
          <View style={{ alignItems: "center", justifyContent: "center", flex: 1}}>
            <TouchableOpacity onPressOut={() => navigation.navigate("Urszula")} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() }}>
              <Image source={Obrazy.SiostryTrumna} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() }} />
            </TouchableOpacity>
          </View>
        </View>
        */}
        { Platform.OS === 'web' &&
        <FullWidthImage source={Obrazy.D1Baner2} />
        }
        { Platform.OS !== 'web' &&
        <Image source={Obrazy.D1Baner2} style={{width: "100%", height: undefined, aspectRatio: 1920 / 592}} />
        }
        <View style={[styles.contentContainer, { zIndex: -1, height: 100 }]}>
          <View style={styles.content1}></View>
          <View style={styles.content1}></View>
        </View>
{/*
        <View style={styles.contentContainer}>
          <View style={{ position: "absolute", width: "100%", height: 300 / PixelRatio.get(), backgroundColor: "#c9c9c9" }}>
          </View>
          <View style={{ marginTop: -100 / PixelRatio.get(), alignItems: "center", justifyContent: "center", flex: 1}}>
            <Text style={styles.tytulWiekszy}>Zajrzyj do środka</Text>
          </View>
          <View style={{ marginTop: -100 / PixelRatio.get(), alignItems: "center", justifyContent: "center", flex: 1}}>
            <TouchableOpacity onPressOut={() => navigation.navigate("Urszula")} style={{ width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get(), borderWidth: 1, borderColor: 'red'}}>
              <Image source={Obrazy.SiostryTrumna} style={{ position: "absolute", width: 500 / PixelRatio.get(), height: 500 / PixelRatio.get() }} />
            </TouchableOpacity>
          </View>
        </View>
        <View style={[styles.contentContainer, {zIndex: -1, height: 500, marginTop: -100 / PixelRatio.get()}]}>
          <Text>Tekst</Text>
        </View>
*/}
        { Platform.OS === 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <FullWidthImage source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View onContextMenu={(e)=>{e.preventDefault();}} style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/D1.mp4", width: 1920, height: 1080 }}
              onLoad={()=>{
                document.querySelector("video").controlsList = "nodownload";
                RNAnimated.timing(opacity, {
                toValue: 1,
                ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1920 / 1080 }]}
            />
          </RNAnimated.View>
        </View>
        }
        { Platform.OS !== 'web' &&
        <View onLayout={(e) => setVideoHeight(e.nativeEvent.layout.height)}>
          <Image source={Obrazy.Kino} style={styles.kino} />
          <RNAnimated.View style={{ opacity: opacity, alignSelf: 'center', position: "absolute", top: "5%", height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }}>
            <Video
              ref={videoRef}
              source={{ uri: PREFIX + "piecpiaty.pl/assets/D1m.mp4", width: 1280, height: 720 }}
              onLoad={()=>{RNAnimated.timing(opacity, {
                toValue: 1,
                ...Platform.select({ "native": { useNativeDriver: true } }),
              }).start();}}
              rate={1.0}
              volume={1.0}
              isMuted={false}
              resizeMode="contain"
              shouldPlay={false}
              useNativeControls
              style={[styles.video, { height: videoHeight * 0.7, width: videoHeight * 0.7 * 1280 / 720 }]}
            />
          </RNAnimated.View>
        </View>
        }
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Dawno, dawno temu…</Text>
          </View>
          <View style={[styles.content2, styles.galeria]}>
                {dawno.map((zdjecie, i) => 
                <TouchableWithoutFeedback key={"zd"+i} onPress={() => navigation.navigate("Zdjecie", { zdjecie, indeks: i, zdjecia: dawno })}>
                  <SharedElement id={zdjecie.id}>
                    <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                  </SharedElement>
                </TouchableWithoutFeedback>
                )}
          </View>
        </View>
        <View style={styles.contentContainer}>
          <View style={styles.content1}>
            <Text style={[styles.tytul, { fontSize: fontSize(42) }]}>Aktualnie…</Text>
          </View>
          <View style={[styles.content2, styles.galeria]}>
                {aktualnie.map((zdjecie, i) => 
                <TouchableWithoutFeedback key={"za"+i} onPress={() => navigation.navigate("Zdjecie", { zdjecie, indeks: i, zdjecia: aktualnie })}>
                  <SharedElement id={zdjecie.id}>
                    <Img preview={zdjecie.preview} source={zdjecie.zdjecie} style={styles.zdjecie} hoverScale fadeInPreview />
                  </SharedElement>
                </TouchableWithoutFeedback>
                )}
          </View>
        </View>
        <Stopka />
      </ScrollView>
      </Kratka>
    );
}
