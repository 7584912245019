import { Platform, StyleSheet, PixelRatio } from 'react-native';

export const PREFIX = "https://"; // "https://";

export const styles = StyleSheet.create({
    container: {
      backgroundColor: 'white'
    },
    domek: {
      width: '100%',
      height: undefined,
      aspectRatio: 1920 / 356,
    },
    pionek: {
      width: 200 / PixelRatio.get(),
      height: 200 / PixelRatio.get() * 560 / 258,
      position: "absolute",
      bottom: -100 / PixelRatio.get(), 
      alignSelf: "center"
    },
    kino: {
      ...Platform.select({
        "native": {
          width: '100%',
          height: undefined,
          aspectRatio: 1920 / 757,
        },
        "web": {
          width: 1920,
          height: 757,
        },
      })
    },
    contentContainer: {
      flexDirection: "row",
    },
    content1: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    content2: {
      flex: 2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    contentOpis: {
      padding: "8%",
      backgroundColor: 'white',
    },
    paddingH: {
      paddingHorizontal: "8%",
    },
    paddingL: {
      paddingLeft: "8%",
    },
    paddingR: {
      paddingRight: "8%",
    },
    tytul: {
      paddingLeft: "10%",
      fontFamily: "Sacramento_400Regular",
      fontSize: (Platform.OS === "web" ) ? 46 : 22,
    },
    /*
    tytulWiekszy: {
      paddingLeft: "10%",
      fontFamily: "Sacramento_400Regular",
      fontSize: (Platform.OS === "web" ) ? 86 : 22,
    },
    */
    opis: {
      textAlign: "justify",
      fontFamily: "CenturyGothic-Regular",
      fontSize: (Platform.OS === "web" ) ? 24 : 12,
    },
    video: {
      /*width: 1200 / PixelRatio.get(),
      height: 810 / PixelRatio.get(),
      margin: 5,*/
    },
    galeria: {
      flexDirection: "row",
      flexWrap: 'wrap',
      justifyContent: "flex-start"
    },
    zdjecie: {
      resizeMode: 'cover',
      margin: Math.floor(20 / PixelRatio.get()),
      width: Math.floor(200 / PixelRatio.get()),
      height: Math.floor(200 / PixelRatio.get()),
      ...Platform.select({
        web: {
          cursor: 'pointer',
          transition: "all 0.5s"
        },
      }),
    },
    arrow: {
      margin: 5,
    },
  });
  